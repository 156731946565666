import React, { Component } from "react";
import glamorous from "glamorous";
import serialize from "form-serialize";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";

import ImageJellycatLogoPng from "../static/ImageJellycatLogo.png";
import ImageFixedProportions from "../components/web/images/ImageFixedProportions.js";
import InputText from "../components/web/inputs/InputText.js";
import Button from "../components/web/buttons/Button.js";
import P from "../components/web/typography/P.js";
import ModalFadeInForForms from "../components/web/modals/ModalFadeInForForms.js";
import AuthService from "../components/web/auth/AuthService.js";

import ImageJellycatLogoPlaceholderPng from "../static/ImageJellycatLogoPlaceholder.png";

const Container = glamorous.div(props => ({
  height: "100vh",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "flex-start",
  fontFamily: props.theme.fontPrimary
}));
Container.displayName = "views.LoginView.Container";

class LoginView extends Component {
  static displayName = "views.LoginView";

  constructor(props) {
    super(props);

    this.state = {
      formErrors: "",
      isPosting: false,
      success: false,
      formInitialized: false,
      formValues: {},
      isLoading: true
    };
    this.Auth = new AuthService(process.env.REACT_APP_BACKEND_BASE_URL);
  }

  componentDidMount() {
    this.setState({ isLoading: false });
  }

  handleSubmit(e) {
    e.preventDefault();
    const serializedFormValues = serialize(this.form, { hash: true });
    this.setState(
      {
        formValues: {
          email: serializedFormValues.email,
          password: serializedFormValues.password
        }
      },
      () => {
        this.Auth.login(
          this.state.formValues.email,
          this.state.formValues.password
        )
          .then(res => {
            window.location.replace("/products/");
          })
          .then(
            fetch(
              `${
                process.env.REACT_APP_BACKEND_BASE_URL
              }/auth/get_csrf_token_v2/`
            )
              .then(res => {
                // need to clone response as res.json() throws error - body stream is locked
                return res.clone().json();
              })
              .then(data => {
                Cookies.set("csrftoken", `${data.csrfToken}`, {
                  expires: 365,
                  MaxAge: 31449600,
                  path: "/",
                  SameSite: "Lax",
                  domain: ".jellycat.sg"
                });
              })
          )
          .catch(err => {
            this.setState({
              formErrors: {
                formError:
                  "Looks like either your email or password were incorrect.  Please try again."
              }
            });
          });
      }
    );
  }

  render() {
    const { formErrors, isLoading } = this.state;
    if (isLoading) {
      return null;
    }
    return (
      <Container className="mt-5">
        <div className="col-10 col-md-8 col-lg-6">
          <div style={{ width: "300px", margin: "0 auto" }}>
            <ImageFixedProportions
              src={ImageJellycatLogoPng}
              placeholderPng={ImageJellycatLogoPlaceholderPng}
            />
          </div>
          <div>&nbsp;</div>
          <div className="mt-2">
            <ModalFadeInForForms
              formErrors={formErrors}
              errorMsg={formErrors.formError}
            />
          </div>

          <div className="mt-3" style={{ width: "100%" }}>
            <form
              method="post"
              action="."
              onSubmit={e => this.handleSubmit(e)}
              ref={ref => (this.form = ref)}
            >
              <InputText
                labelName="Username"
                placeholder="Enter your username"
                inputName="email"
                type="text"
              />
              <InputText
                labelName="Password"
                type="password"
                placeholder="Enter your password"
                inputName="password"
              />
              <Link to="/password/reset/" className="text-left">
                <P small secondary underline>
                  Forgot my password
                </P>
              </Link>
              <Button isFullWidth className="mt-3">
                Login
              </Button>
            </form>
          </div>
        </div>
      </Container>
    );
  }
}

export default LoginView;
