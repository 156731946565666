import React, { Component } from "react";
import glamorous from "glamorous";
import serialize from "form-serialize";

import ImageJellycatLogoPng from "../static/ImageJellycatLogo.png";
import ImageFixedProportions from "../components/web/images/ImageFixedProportions.js";
import InputText from "../components/web/inputs/InputText.js";
import Button from "../components/web/buttons/Button.js";
import ModalFadeInForForms from "../components/web/modals/ModalFadeInForForms.js";

import ImageJellycatLogoPlaceholderPng from "../static/ImageJellycatLogoPlaceholder.png";

const Container = glamorous.div(props => ({
  height: "100vh",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "flex-start",
  fontFamily: props.theme.fontPrimary
}));
Container.displayName = "views.LoginView.Container";

class PasswordResetView extends Component {
  static displayName = "views.PasswordResetView";

  constructor(props) {
    super(props);
    this.state = {
      email: "",
      formErrors: ""
    };
  }

  componentDidMount() {
    this.setState({ token: this.props.match.params.token });
  }

  handleSubmit(e) {
    e.preventDefault();
    const formValues = serialize(this.form, { hash: true });
    if (formValues.password === undefined) {
      this.setState({
        formErrors: {
          passwordFormError: "Password cannot be blank"
        }
      });
    }

    fetch(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/auth/password_reset/confirm/`,
      {
        method: "POST",
        headers: new Headers({
          Accept: "application/json",
          "Content-Type": "application/x-www-form-urlencoded"
        }),
        body: `password=${formValues.password}&token=${this.state.token}`
      }
    )
      .then(results => {
        if (results.status === 200) {
          this.props.history.push("/");
        }
        return results;
      })
      .catch(error => {
        console.error(error);
      });
  }

  render() {
    const { formErrors } = this.state;
    return (
      <Container className="mt-5">
        <div className="col-10 col-md-8 col-lg-6">
          <div style={{ width: "300px", margin: "0 auto" }}>
            <ImageFixedProportions
              src={ImageJellycatLogoPng}
              placeholderPng={ImageJellycatLogoPlaceholderPng}
            />
          </div>
          <div>&nbsp;</div>
          <div className="mt-2">
            <ModalFadeInForForms
              formErrors={formErrors}
              errorMsg={"Please correct the errors below"}
            />
          </div>

          <div className="mt-3" style={{ width: "100%" }}>
            <form
              method="post"
              action="."
              onSubmit={e => this.handleSubmit(e)}
              ref={ref => (this.form = ref)}
            >
              <InputText
                labelName="Password"
                placeholder="Enter new password"
                inputName="password"
                type="password"
                error={formErrors.passwordFormError}
              />
              <Button isFullWidth className="mt-3">
                Reset my password
              </Button>
            </form>
          </div>
        </div>
      </Container>
    );
  }
}

export default PasswordResetView;
