import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import glamorous from "glamorous";
import { withRouter } from "react-router";

import AuthService from "../auth/AuthService.js";
import P from "../typography/P.js";

const NavItemDiv = glamorous.div(props => ({
  backgroundColor: props.theme.colorWhite,
  color: "black",
  cursor: "pointer",
  borderBottom: "1px solid gray",
  padding: "0.5em",
  textAlign: "left"
}));

const Auth = new AuthService(process.env.REACT_APP_BACKEND_BASE_URL);

class NavigationProfile extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired
  };

  handleLogout() {
    Auth.logout();
    this.props.history.replace("/");
  }

  render() {
    const navigationItems = [
      {
        name: "Account Overview",
        linkTo: "/profile/account-overview/"
      },
      {
        name: "My Orders",
        linkTo: "/profile/orders/"
      },
      {
        name: "All Backordered Items",
        linkTo: "/profile/backorders/"
      },
      {
        name: "My Details",
        linkTo: "/profile/details/"
      },
      {
        name: "Change Password",
        linkTo: "/profile/change-password/"
      },
      {
        name: "Logout"
      }
    ];

    const currPathname = this.props.location.pathname;

    return (
      <div>
        {navigationItems.map(item => (
          <NavItemDiv key={item.name}>
            {!!item.linkTo && (
              <Link
                to={item.linkTo}
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <P
                  large
                  className="mb-0"
                  style={
                    currPathname === item.linkTo
                      ? { color: "#5ba8b4" }
                      : { color: "black" }
                  }
                >
                  {item.name}
                </P>
              </Link>
            )}
            {item.name === "Logout" && (
              <P large className="mb-0" onClick={() => this.handleLogout()}>
                {item.name}
              </P>
            )}
          </NavItemDiv>
        ))}
      </div>
    );
  }
}

export default withRouter(NavigationProfile);
