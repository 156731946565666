import React, { Component } from "react";
import PropTypes from "prop-types";
import glamorous from "glamorous";

import P from "../typography/P.js";
import InputIconAddOn from "./InputIconAddOn.js";
import ImageResponsive from "../images/ImageResponsive.js";
import IconCaretDownSvg from "../../../static/IconCaretDown.svg";
import { focusInput } from "../../theme.js";

const CustomInput = glamorous.div(
  props => ({
    backgroundColor: props.isHovered ? props.theme.colorGrayTopBar : "white",
    transition: "background-color 0.5s ease",
    borderRadius: "10px",
    border: "1px solid white",
    boxShadow: "none",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    opacity: props.disabled ? 0.6 : 1,
    cursor: props.disabled ? "not-allowed" : "pointer",
    position: "relative",
    padding: "5px"
  }),
  props => {
    if (props.isFocused) {
      return focusInput;
    }
    return undefined;
  }
);
CustomInput.displayName = "art.inputs.InputSelect.CustomInput";

class InputSelect extends Component {
  static propTypes = {
    label: PropTypes.string
  };

  static defaultProps = {
    label: ""
  };

  constructor(props) {
    super(props);
    // if `value` prop is provided, we ignore `initialValue` prop
    let value = props.initialValue;
    if (props.value) {
      value = props.value;
    }

    this.state = { isFocused: false, value };
  }

  componentDidMount() {
    let value = this.props.initialValue;
    if (this.props.value) {
      value = this.props.value;
    }

    this.setState({
      isFocused: false,
      value: value
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.value !== this.props.value) {
      this.setState({ value: this.props.value });
    }
    if (
      prevProps.initialValue !== this.props.initialValue ||
      prevProps.addedToCart !== this.props.addedToCart
    ) {
      this.setState({ value: this.props.initialValue });
    }
  }

  handleChange(e) {
    const newValue = e.target.value;
    this.setState({ value: newValue });
    if (this.props.onChange) {
      this.props.onChange(e);
    }
  }

  getDisplayValueInputSelect(value, data) {
    if (!data) {
      return "";
    }
    let result;
    data.forEach(item => {
      if (`${item[0]}` === `${value}`) {
        result = item[0];
      }
    });
    return result;
  }

  handleMouseEnter() {
    this.setState({ isHovered: true });
  }

  handleMouseLeave() {
    this.setState({ isHovered: false });
  }

  render() {
    const { disabled, name, data, label } = this.props;
    const { isFocused, isHovered, value } = this.state;

    return (
      <div
        style={{
          display: "flex",
          marginBottom: "1em"
        }}
        onMouseEnter={() => this.handleMouseEnter()}
        onMouseLeave={() => this.handleMouseLeave()}
      >
        {!!label && (
          <P className="mb-0" style={{ paddingTop: "5px" }}>
            {label}
          </P>
        )}
        <CustomInput isFocused={isFocused} isHovered={isHovered}>
          <P className="mb-0 ml-2">
            {value}
            {/*{this.getDisplayValueInputSelect(value, data)}*/}
          </P>
          <InputIconAddOn style={{ marginTop: "-3px" }}>
            <ImageResponsive src={IconCaretDownSvg} />
          </InputIconAddOn>

          <select
            style={{
              cursor: disabled ? "not-allowed" : "pointer",
              opacity: 0,
              position: "absolute",
              width: "100%",
              top: 0,
              left: 0,
              background: "#fff"
            }}
            disabled={disabled}
            id={`id_${name}`}
            name={name}
            onChange={e => this.handleChange(e)}
            value={value}
            ref={ref => (this.selectRef = ref)}
          >
            {data.map(item => (
              <option key={item[0]} value={item[0]}>
                {item[1]}
              </option>
            ))}
          </select>
        </CustomInput>
      </div>
    );
  }
}

export default InputSelect;
