import glamorous from 'glamorous'

import ModalFadeInBase from './ModalFadeInBase.js'

const ModalFadeInSuccess = glamorous(ModalFadeInBase)(props => ({
  backgroundColor: props.theme.colorSuccess,
}))
ModalFadeInSuccess.displayName = 'web.modals.ModalFadeInSuccess'

export default ModalFadeInSuccess
