import React, { Component } from "react";
import glamorous from "glamorous";

import InputLabel from "./InputLabel.js";
import InputIconAddOn from "./InputIconAddOn.js";
import InputFormError from "./InputFormError.js";
import ImageResponsive from "../images/ImageResponsive.js";

const InputTextInput = glamorous.input(props => ({
  backgroundColor: "white",
  border: `1px solid ${props.theme.colorGrayLight}`,
  borderRadius: "20px",
  boxSizing: "border-box",
  cursor: props.disabled ? "not-allowed" : "text",
  height: props.theme.heightInput,
  lineHeight: "1.1em",
  opacity: props.disabled ? 0.6 : 1,
  paddingTop: "0em",
  paddingRight: "1em",
  paddingBottom: "0m",
  paddingLeft: props.hasIconLeft ? `${props.theme.heightInputNum}px` : "1em",
  WebkitAppearance: "none",
  width: "100%",
  fontFamily: props.theme.fontPrimary,
  fontSize: props.theme.fontSizePSmall,
  ":focus": {
    border: `1px solid ${props.theme.colorPrimary}`,
    borderRadius: "20px",
    outline: "none"
  },
  "&::placeholder": {
    color: props.theme.colorGrayLight
  }
}));
InputTextInput.displayName = "web.InputText.InputTextInput";

class InputText extends Component {
  constructor(props) {
    super(props);
    this.state = { value: props.initialValue || props.value || "" };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.initialValue !== this.props.initialValue) {
      this.setState({
        value: this.props.initialValue
      });
    }
  }
  handleChange(e) {
    // const validValue = e.target.validity.valid
    //   ? e.target.value
    //   : this.state.value
    this.setState({ value: e.target.value });
  }

  render() {
    const {
      labelName,
      disabled,
      placeholder,
      inputName,
      error,
      type,
      customStyle,
      iconRightSrc
    } = this.props;
    const { value } = this.state;

    return (
      <div style={{ paddingBottom: "1em", ...customStyle }}>
        {labelName && (
          <InputLabel disabled={disabled} className="mb-2 text-left">
            {labelName}
          </InputLabel>
        )}
        <div
          style={{
            display: "flex",
            position: "relative",
            alignItems: "center"
          }}
        >
          <div style={{ flex: "1 0 0%" }}>
            <InputTextInput
              disabled={disabled}
              onChange={e => this.handleChange(e)}
              placeholder={placeholder}
              value={value}
              type={type}
            />
            <input
              disabled={disabled}
              name={inputName}
              type="hidden"
              value={value}
            />
          </div>
          <div
            style={{
              position: "absolute",
              right: 0,
              paddingRight: "8px",
              flex: "0 0 0%"
            }}
          >
            {iconRightSrc && (
              <InputIconAddOn
                disabled={disabled}
                onClick={value => this.props.handleOnClick(value)}
              >
                <ImageResponsive
                  src={iconRightSrc}
                  style={{ maxWidth: "100%", maxHeight: "100%" }}
                />
              </InputIconAddOn>
            )}
          </div>
        </div>
        <InputFormError className="text-left">{error}</InputFormError>{" "}
      </div>
    );
  }
}
export default InputText;
