import React, { Component } from "react";
import PropTypes from "prop-types";

class ImageFixedProportions extends Component {
  static displayName = "web.images.ImageFixedProportions";

  static propTypes = {
    backgroundSize: PropTypes.string,
    filterBW: PropTypes.bool,
    placeholderPng: PropTypes.string.isRequired,
    src: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    alt: PropTypes.string
  };

  static defaultProps = {
    backgroundSize: "cover",
    filterBW: false,
    src: undefined,
    alt: ""
  };

  render() {
    const {
      alt,
      src,
      placeholderPng,
      backgroundSize,
      filterBW,
      style
    } = this.props;

    return (
      <div
        style={{
          position: "relative",
          width: "100%",
          height: "auto",
          ...style
        }}
      >
        <div
          style={{
            backgroundImage: src ? `url(${src})` : "none",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize,
            filter: filterBW ? "grayscale(100%)" : "none",
            position: "absolute",
            bottom: 0,
            left: 0,
            right: 0,
            top: 0
          }}
        />
        <img
          src={placeholderPng}
          alt={alt}
          style={{ width: "100%", height: "auto" }}
        />
      </div>
    );
  }
}

export default ImageFixedProportions;
