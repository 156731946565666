import { SHOW_CART_POPOVER, UPDATE_CART_BADGE } from "../actionTypes.js";

const initialState = {
  showCartPopover: false,
  updateCartBadge: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SHOW_CART_POPOVER: {
      const { isVisible } = action.payload;
      return {
        ...state,
        showCartPopover: isVisible
      };
    }
    case UPDATE_CART_BADGE: {
      const { update } = action.payload;
      return {
        ...state,
        updateCartBadge: update
      };
    }
    default:
      return state;
  }
}
