import glamorous from "glamorous";

const P = glamorous.p(
  props => ({
    color: props.theme.colorBlack,
    fontFamily: props.theme.fontPrimary,
    fontSize: props.theme.fontSizeP, // 12pt,
    letterSpacing: props.theme.letterSpacingNormal
  }),
  props =>
    props.larger && {
      fontSize: props.theme.fontSizePLarger //15pt
    },
  props =>
    props.large && {
      fontSize: props.theme.fontSizePLarge //  13pt
    },
  props =>
    props.medium && {
      fontSize: props.theme.fontSizePMedium // 11pt
    },
  props => props.mediumSmall && { fontSize: props.theme.fontSizePMediumSmall }, // 10.5pt
  props =>
    props.small && {
      fontSize: props.theme.fontSizePSmall // 10 pt
    },
  props =>
    props.smaller && {
      fontSize: props.theme.fontSizePSmaller // 9 pt
    },
  props =>
    props.smallest && {
      fontSize: props.theme.fontSizePSmallest // 8 pt
    },
  props =>
    props.fineprint && {
      fontSize: props.theme.fontSizePFineprint // 7 pt
    },
  props =>
    props.fineprintSmall && {
      fontSize: props.theme.fontSizePFineprintSmall // 5 pt
    },
  props =>
    props.secondary && {
      color: props.theme.colorSecondary
    },
  props =>
    props.gray && {
      color: props.theme.colorTextGray
    },
  props =>
    props.grayLight && {
      color: props.theme.colorGrayLight
    },
  props => props.grayMedium && { color: props.theme.colorGrayMedium },
  props => props.grayDark && { color: props.theme.colorGrayDark },
  props =>
    props.primary && {
      color: props.theme.colorPrimary
    },
  props =>
    props.white && {
      color: "white"
    },
  props =>
    props.danger && {
      color: props.theme.colorDanger
    },
  props => props.colorLink && { color: props.theme.colorLink },
  props =>
    props.narrow && {
      letterSpacing: props.theme.letterSpacingNarrow
    },
  props =>
    props.wide && {
      letterSpacing: props.theme.letterSpacingWide
    },
  props =>
    props.letterSpacingButtonText && {
      letterSpacing: props.theme.letterSpacingButtonText
    },
  props =>
    props.hoverPrimary && {
      cursor: "pointer",
      transition: `color ${props.theme.transitionSpeed}s ease`,
      ":hover": {
        color: props.theme.colorPrimary
      },
      "> a": {
        textDecoration: "none",
        ":hover": {
          color: props.theme.colorPrimary
        }
      }
    },
  props =>
    props.uppercase && {
      textTransform: "uppercase"
    },
  props =>
    props.role === "button" && {
      textDecoration: "underline"
    },
  props =>
    props.ellipsis && {
      maxWidth: "100%",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap"
    },
  props =>
    props.inlineBlock && {
      display: "inline-block"
    },
  props =>
    props.fontSansSerif && {
      fontFamily: props.theme.fontSansSerif
    },
  props =>
    props.fontWeightLight && {
      fontWeight: props.theme.fontWeightLight
    },
  props =>
    props.fontWeightHeavy && {
      fontFamily: props.theme.fontPrimaryFontWeightHeavy
    },
  props =>
    props.underline && {
      textDecoration: "underline"
    },
  props =>
    props.fontColorAlert && {
      color: props.theme.colorBadgeDanger
    },
  props =>
    props.italics && {
      fontStyle: "italic"
    },

  props =>
    props.bold && {
      fontWeight: 800
    }
);

P.displayName = "web.typography.P";
export default P;
