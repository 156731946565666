import React, { Component } from "react";
import decode from "jwt-decode";

import "./App.css";

import { ThemeProvider } from "glamorous";
import { BrowserRouter, Redirect } from "react-router-dom";
import Switch from "react-router-dom/Switch";
import Route from "react-router-dom/Route";

import { theme } from "./components/theme.js";
import NavigationMain from "./components/web/navigation/NavigationMain.js";

import LoginView from "./views/LoginView.js";
import ForgotPasswordView from "./views/ForgotPasswordView.js";
import PasswordResetView from "./views/PasswordResetView.js";
import ProductGridView from "./views/ProductGridView.js";
import CartView from "./views/CartView.js";
import ProfileView from "./views/ProfileView.js";
import OrderSuccessView from "./views/OrderSuccessView.js";
import PasswordResetInstructionsView from "./views/PasswordResetInstructionsView.js";
import OrdersView from "./views/OrdersView.js";
import OrderDetailView from "./views/OrderDetailView.js";

const PasswordViews = () => (
  <div className="container">
    <Route
      exact
      path="/password/reset/key/:token/"
      component={PasswordResetView}
    />
    <Route exact path="/password/reset/" component={ForgotPasswordView} />
    <Route
      exact
      path="/password/reset/instructions/"
      component={PasswordResetInstructionsView}
    />
  </div>
);

const token = localStorage.getItem("id_token");
const decodedToken = !!token ? decode(token) : 0;
let tokenIsValid;
if (decodedToken.exp < Date.now() / 1000) {
  // Checking if token is expired.
  tokenIsValid = false;
  // console.log("tokenExpired", decodedToken.exp, Date.now() / 1000);
} else {
  // console.log("tokenValid", decodedToken.exp, Date.now() / 1000);
  tokenIsValid = true;
}

const AllViews = () =>
  token !== null && tokenIsValid ? (
    <div>
      <Route
        path="/(products|profile|cart|order)/"
        component={NavigationMain}
      />
      <div className="container">
        <Switch>
          <Route
            path="/products/search/:searchTerm?"
            component={ProductGridView}
          />
          <Route path="/products/:category?" component={ProductGridView} />
        </Switch>
      </div>
      <Switch>
        <Route path="/profile/" component={ProfileView} />
        <Route path="/profile/orders/" component={OrdersView} />
        <Route path="/profile/orders/:orderId" component={OrderDetailView} />
        <Route exact path="/cart/" component={CartView} />
        <Route exact path="/order/success/" component={OrderSuccessView} />
      </Switch>
    </div>
  ) : (
    <div>
      {console.log("redirect")}

      <Redirect to="/" />
    </div>
  );

class App extends Component {
  render() {
    return (
      <div className="App">
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <Switch>
              <div>
                <Route exact path="/password/*" component={PasswordViews} />
                <div className="container">
                  <Route exact path="/" component={LoginView} />
                </div>
                <Route
                  path="/(products|profile|cart|order)/"
                  component={AllViews}
                />
              </div>
            </Switch>
          </BrowserRouter>
        </ThemeProvider>
      </div>
    );
  }
}

export default App;
