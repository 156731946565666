import React, { Component } from "react";
import glamorous from "glamorous";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";
import { connect } from "react-redux";

import BackTop from "antd/lib/back-top";

import { withAuth } from "../components/utils/index.js";
import { UPDATE_CART_BADGE } from "../redux/actionTypes.js";

import H1 from "../components/web/typography/H1.js";
import P from "../components/web/typography/P.js";
import Button from "../components/web/buttons/Button.js";
import CartRow from "../components/web/cart/CartRow.js";
import ModalLoading from "../components/web/modals/ModalLoading.js";
import ErrorMessageGeneral from "../components/web/misc/ErrorMessageGeneral.js";

const overlayZIndex = 1;
const grayBackgroundZIndex = 0;
const contentZIndex = "auto";

const Background = glamorous.div(props => ({
  backgroundColor: props.theme.colorGrayTopBar,
  position: "absolute",
  width: "100vw",
  left: 0,
  zIndex: grayBackgroundZIndex
}));
Background.displayName = "views.CartView.Background";

const OverlayDiv = glamorous.div(props => ({
  backgroundColor: props.theme.colorGrayLight,
  opacity: "0.4",
  width: "100vw",
  height: "100vh",
  position: "fixed",
  top: 0,
  left: 0,
  zIndex: overlayZIndex,
  transition: "opacity 1s ease"
}));
OverlayDiv.displayName = "views.CartView.OverlayDiv";

const Table = glamorous.table(props => ({
  width: "100%",
  fontFamily: props.fontPrimary,
  textAlign: "left",
  tableLayout: "auto"
}));
Table.displayName = "views.CartView.Table";

const Container = glamorous.div(props => ({
  padding: "1em",
  display: "flex",
  position: "relative",
  width: "100%",
  "@media(min-width: 767px)": {
    padding: "2em 3em"
  }
}));
Container.displayName = "views.CartView.Container";

const MyCartDiv = glamorous.div(props => ({
  backgroundColor: "white",
  padding: "1em"
}));
MyCartDiv.displayName = "views.CartView.MyCartDiv";

const TotalDiv = glamorous.div(props => ({
  backgroundColor: "white",
  padding: "1em"
}));
TotalDiv.displayName = "views.CartView.TotalDiv";

const RetailPriceTotalDiv = glamorous.div(props => ({
  backgroundColor: "white",
  padding: "1em",
  marginBottom: "1em"
}));
RetailPriceTotalDiv.displayName = "views.CartView.RetailPriceTotalDiv";

const Divider = glamorous.div(props => ({
  "@media(min-width: 767px)": {
    width: "2em"
  }
}));
Divider.displayName = "views.CartView.Divider";

class CartView extends Component {
  static displayName = "views.CartView";

  constructor(props) {
    super(props);
    let jwtToken = localStorage.getItem("id_token");
    this.state = {
      jwtToken: jwtToken,
      showOverlay: false,
      data: [],
      isLoading: false,
      error: null
    };
  }

  componentDidMount() {
    this.setState({ isLoading: true });

    this.props.auth
      .fetch(
        `${
          process.env.REACT_APP_BACKEND_BASE_URL
        }/order_platform/get_cart_items/`
      )
      .then(data => {
        this.setState({
          data: data,
          isLoading: false
        });
      })
      .catch(error => {
        this.setState({ error: true, isLoading: false });
      });
  }

  handleUpdate(value, id) {
    this.setState({ showOverlay: false, isLoading: true });
    let csrfToken = Cookies.get("csrftoken");

    this.props.auth
      .fetch(
        `${
          process.env.REACT_APP_BACKEND_BASE_URL
        }/order_platform/update_cart_item_quantity/`,
        {
          method: "PUT",
          body: `cartitem_id=${id}&quantity=${value}&csrfmiddlewaretoken=${csrfToken}`,
          credentials: "include"
        },
        {
          "Content-Type": "application/x-www-form-urlencoded",
          "x-csrftoken": csrfToken
        }
      )
      .then(data => {
        let currData = this.state.data;
        let updatedData = currData.map(item => {
          return item.id === data.id
            ? { ...item, quantity: data.quantity }
            : item;
        });
        this.setState({ data: updatedData, isLoading: false });
      })
      .catch(error => {
        this.setState({ error: true, isLoading: false });
      });
  }

  handleRemoveCartItem(id) {
    this.setState({ isLoading: true });
    let csrfToken = Cookies.get("csrftoken");

    this.props.auth
      .fetch(
        `${
          process.env.REACT_APP_BACKEND_BASE_URL
        }/order_platform/delete_cart_item/`,
        {
          method: "DELETE",
          body: `id=${id}&csrfmiddlewaretoken=${csrfToken}`,
          credentials: "include"
        },
        {
          "Content-Type": "application/x-www-form-urlencoded",
          "x-csrftoken": csrfToken
        }
      )
      .then(data => {
        let currData = this.state.data;
        let updatedData = [];
        currData.forEach(item => {
          if (item.id !== data) {
            updatedData.push(item);
          }
        });
        this.setState({ data: updatedData, isLoading: false });
        this.props.dispatch({
          type: UPDATE_CART_BADGE,
          payload: {
            update: !this.props.updateCartBadge
          }
        });
      })
      .catch(error => {
        this.setState({ error: true, isLoading: false });
      });
  }

  handleChange() {
    this.setState({
      showOverlay: true
    });
  }

  handleCancel() {
    this.setState({ showOverlay: false });
  }

  getTotalRRP() {
    const { data } = this.state;
    let result = 0;
    data.forEach(item => {
      result += item.quantity * item.product.suggested_retail_price;
    });
    return result.toFixed(2);
  }

  getSubtotalWithoutGST() {
    const { data } = this.state;
    let result = 0;
    data.forEach(item => {
      result += item.quantity * item.product.trade_price;
    });
    return result.toFixed(2);
  }

  getSubtotalWithGST() {
    const { data } = this.state;
    let result = 0;
    data.forEach(item => {
      result += item.quantity * item.product.trade_price_gst;
    });
    return result.toFixed(2);
  }

  getTotal() {
    const { data } = this.state;
    let result = 0;
    data.forEach(item => {
      result += item.quantity * item.product.trade_price_gst;
    });
    return result.toFixed(2);
  }

  handleSubmitButtonClick() {
    let orderId = this.state.data[0].order_id;
    this.setState({ isLoading: true });

    let csrfToken = Cookies.get("csrftoken");

    this.props.auth
      .fetch(
        `${
          process.env.REACT_APP_BACKEND_BASE_URL
        }/order_platform/submit_order/`,
        {
          method: "PUT",
          body: `order_id=${orderId}&csrfmiddlewaretoken=${csrfToken}`,
          credentials: "include"
        },
        {
          "Content-Type": "application/x-www-form-urlencoded",
          "x-csrftoken": csrfToken
        }
      )
      .then(data => {
        this.props.history.push({
          pathname: `/order/success/`,
          state: {
            orderId: data.id,
            orderDate: data.order_submission_date
          }
        });

        // this.props.history.push("/order/success/");
        this.setState({ isLoading: false });
        this.props.dispatch({
          type: UPDATE_CART_BADGE,
          payload: {
            update: !this.props.updateCartBadge
          }
        });
      })
      .catch(error => {
        this.setState({ error: true, isLoading: false });
      });
  }

  renderNonEmptyCart(data) {
    const { showOverlay } = this.state;

    return (
      <div
        style={{ width: "100%", zIndex: contentZIndex }}
        className="d-md-flex"
      >
        <MyCartDiv style={{ flex: 2 }}>
          <H1 medium className="text-left col-12 mt-2">
            MY CART
          </H1>
          <hr />
          {!!data &&
            data.map((item, index) => [
              <CartRow
                onCancel={() => this.handleCancel()}
                item={item}
                onUpdate={(value, id) => this.handleUpdate(value, id)}
                onChange={() => this.handleChange()}
                onRemoveCartItem={id => this.handleRemoveCartItem(id)}
                overlayIsVisible={showOverlay}
                key={`CartRow-${!!item && !!item.product && item.product.id}`}
              />,
              <hr key={`hr-${index}`} />
            ])}
        </MyCartDiv>
        <Divider>&nbsp;</Divider>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <RetailPriceTotalDiv>
            <H1 small className="text-left mt-2">
              RECOMMENDED RETAIL PRICE (RRP)
            </H1>
            <hr />
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <P className="text-left">Total RRP for your cart items</P>
              <P className="text-left">${this.getTotalRRP()}</P>
            </div>
          </RetailPriceTotalDiv>

          <TotalDiv style={{ flex: 1 }}>
            <H1 small className="text-left mt-2">
              TOTAL
            </H1>
            <hr />
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <P className="text-left">Trade Price Total (without GST): </P>
              <P className="text-left">${this.getSubtotalWithoutGST()}</P>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <P className="text-left">Trade Price Total (with GST): </P>
              <P className="text-left">${this.getSubtotalWithGST()}</P>
            </div>

            <hr />
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "1em"
              }}
            >
              <P large className="text-left mb-0">
                Total:{" "}
              </P>
              <P large className="text-left mb-0">
                ${this.getTotal()}{" "}
              </P>
            </div>
            {this.getTotal() < 500 && (
              <P smaller className="text-right" italics>
                *Free delivery within Singapore for orders above SGD$500 <br />
                <span
                  style={{
                    marginBottom: 0,
                    display: "inline-block"
                  }}
                >
                  <Link
                    to="/products/"
                    style={{
                      textDecoration: "underline"
                    }}
                  >
                    Add more items
                  </Link>{" "}
                  to your cart to qualify for free delivery!
                </span>
              </P>
            )}
            <hr />
            <Button
              style={{
                margin: "0 auto",
                padding: "0.8em",
                borderRadius: "10px"
              }}
              onClick={() => this.handleSubmitButtonClick()}
            >
              Submit My Order
            </Button>
            <br />
            <Link to="/products/">
              <P underline>Continue Shopping</P>
            </Link>
          </TotalDiv>
        </div>
      </div>
    );
  }

  renderEmptyCart() {
    return (
      <div
        style={{
          width: "100%",
          zIndex: contentZIndex,
          position: "relative",
          padding: "2em"
        }}
      >
        <P className="mb-4">You do not have any products in your cart.</P>
        <Link
          style={{ textDecoration: "none", color: "inherit" }}
          to={"/products/"}
        >
          <Button className="mx-auto">Start Ordering!</Button>
        </Link>
      </div>
    );
  }

  render() {
    const { showOverlay, data, error, isLoading } = this.state;

    if (error) {
      return <ErrorMessageGeneral />;
    }

    return (
      <React.Fragment>
        {!!isLoading && <ModalLoading isVisible />}
        {!!data &&
          data.length > 0 && (
            <Background>
              <Container>
                {!!showOverlay && <OverlayDiv />}
                {this.renderNonEmptyCart(data)}
                <BackTop />
              </Container>
            </Background>
          )}
        {data.length === 0 && !isLoading && this.renderEmptyCart()}
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    updateCartBadge: state.cart.updateCartBadge
  };
};

export default withAuth(connect(mapStateToProps)(CartView));
