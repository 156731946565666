import glamorous from 'glamorous'

import ModalFadeInBase from './ModalFadeInBase.js'

const ModalFadeInFormErrors = glamorous(ModalFadeInBase)(props => ({
  backgroundColor: props.theme.colorError,
}))
ModalFadeInFormErrors.displayName = 'core.modals.ModalFadeInFormErrors'

export default ModalFadeInFormErrors
