import React, { Component } from "react";
import PropTypes from "prop-types";
import glamorous from "glamorous";
import Modal from "antd/lib/modal";
import Cookies from "js-cookie";

import AuthService from "../auth/AuthService.js";

import OrderItemBadge from "./OrderItemBadge.js";
import Button from "../buttons/Button.js";
import ImageFixedProportions from "../../web/images/ImageFixedProportions.js";
import InputSelect from "../inputs/InputSelect.js";
import P from "../../web/typography/P.js";
import ModalLoading from "../../web/modals/ModalLoading.js";

import ImageProductPlaceholderPng from "../../../static/ImageProductPlaceholder.png";

const OrderItemDiv = glamorous.div(props => ({
  cursor: props.isDisabled ? "not-allowed" : "auto",
  position: "relative"
}));
OrderItemDiv.displayName = "Orders.OrderItem.OrderItemDiv";

const ImageDiv = glamorous.div(props => ({
  opacity: props.isDisabled ? 0.4 : 1
}));
ImageDiv.displayName = "Orders.OrderItem.ImageDiv";

const InfoDiv = glamorous.div(props => ({
  padding: "1em",
  opacity: props.isDisabled ? 0.4 : 1
}));
InfoDiv.displayName = "Orders.OrderItem.InfoDiv";

const Divider = glamorous.hr(props => ({
  marginTop: "5px",
  marginBottom: "5px"
}));
Divider.displayName = "Orders.OrderItem.Divider";

const DisconOverlay = glamorous.div(props => ({
  padding: "1em",
  backgroundColor: props.theme.colorGrayLight,
  width: "100%"
}));
DisconOverlay.displayName = "Orders.OrderItem.DisconOverlay";

const InfoTextDiv = glamorous.div(props => ({
  "@media(min-width: 767px)": {
    minHeight: "170px"
  },
  "@media(min-width: 1200px)": {
    minHeight: "150px"
  }
}));
InfoTextDiv.displayName = "Orders.OrderItem.InfoTextDiv";

const Auth = new AuthService(process.env.REACT_APP_BACKEND_BASE_URL);

class OrderItem extends Component {
  static propTypes = {
    imgSrc: PropTypes.string,
    name: PropTypes.string,
    qtyOrdered: PropTypes.number.isRequired,
    qtyFulfilled: PropTypes.number.isRequired,
    qtyBackorder: PropTypes.number
  };

  static defaultProps = {
    imgSrc: "",
    name: ""
  };

  constructor(props) {
    super(props);
    let jwtToken = localStorage.getItem("id_token");
    const { qtyBackorder } = this.props;
    this.state = {
      jwtToken: jwtToken,
      reduceModalIsVisible: false,
      key: 1,
      error: null,
      isLoading: false,
      qtyBackorderInputSelect: qtyBackorder
    };
  }

  handleReduceButtonClick() {
    this.setState({
      reduceModalIsVisible: true
    });
  }

  handleCloseReduceModal() {
    this.setState({
      reduceModalIsVisible: false,
      key: this.state.key + 1
    });
  }

  handleChangeBackorderQty(e) {
    const { cartItemId, qtyFulfilled } = this.props;
    const { qtyBackorderInputSelect } = this.state;
    let newQtyOrdered =
      parseInt(qtyBackorderInputSelect, 10) + parseInt(qtyFulfilled, 10);
    this.setState({ isLoading: true });

    let csrfToken = Cookies.get("csrftoken");

    Auth.fetch(
      `${
        process.env.REACT_APP_BACKEND_BASE_URL
      }/order_platform/change_backorderitem_quantity/`,
      {
        method: "PUT",
        body: `cartitem_id=${cartItemId}&quantity=${newQtyOrdered}&csrfmiddlewaretoken=${csrfToken}`,
        credentials: "include"
      },
      {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-csrftoken": csrfToken
      }
    )
      .then(data => {
        this.setState({
          isLoading: false,
          reduceModalIsVisible: false
        });
        this.props.changeBackorderQty(false);
      })
      .catch(error => {
        this.props.changeBackorderQty(true);
        this.setState({
          error: true,
          isLoading: false,
          reduceModalIsVisible: false
        });
      });
  }

  renderReduceBackorderQty() {
    return (
      <div
        style={{ marginTop: "10px" }}
        onClick={() => this.handleReduceButtonClick()}
      >
        <Button buttonType="white" style={{ borderRadius: "8px" }}>
          <P smallest className="mb-0" style={{ whiteSpace: "pre-wrap" }}>
            Change Backorder Qty
          </P>
        </Button>
      </div>
    );
  }

  getValuesForReductBackorderQtyInputSelect() {
    let values = [];
    for (var i = 0; i <= 120; i++) {
      values.push([i.toString(), i]);
    }
    return values.reverse();
  }

  handleInputSelectChange(e) {
    this.setState({ [`${e.target.name}InputSelect`]: e.target.value });
  }

  render() {
    const { reduceModalIsVisible, isLoading } = this.state;

    const {
      imgSrc,
      name,
      qtyOrdered,
      qtyFulfilled,
      qtyBackorder,
      status,
      tradePrice
    } = this.props;

    const backorderValue = ((qtyOrdered - qtyFulfilled) * tradePrice).toFixed(
      2
    );
    let isDiscon = status === "DISCON";

    if (isLoading) {
      return <ModalLoading isVisible />;
    }

    return (
      <OrderItemDiv isDisabled={isDiscon}>
        {isDiscon && (
          <DisconOverlay>
            <P smaller className="mb-0">
              Sorry, this item has been discontinued :( We are no longer able to
              fulfill any backorders.
            </P>
          </DisconOverlay>
        )}
        <OrderItemBadge status={status} isDiscon={isDiscon} />
        <ImageDiv isDisabled={isDiscon}>
          <ImageFixedProportions
            src={imgSrc}
            placeholderPng={ImageProductPlaceholderPng}
          />
        </ImageDiv>
        <InfoDiv isDisabled={isDiscon}>
          <InfoTextDiv>
            <P small className="mb-0">
              {name}
            </P>
            <Divider />
            <div>
              <P small className="mb-0">
                Qty Ordered: {status === "DEL" ? qtyFulfilled : qtyOrdered}
              </P>
            </div>
            {qtyBackorder > 0 &&
              status !== "DEL" && (
                <React.Fragment>
                  <Divider />
                  <P small className="mb-0">
                    Backorder Qty: {qtyBackorder}
                  </P>
                </React.Fragment>
              )}
            {qtyBackorder > 0 &&
              status !== "DEL" && (
                <React.Fragment>
                  <Divider />
                  <P small className="mb-0">
                    Backorder Value: ${backorderValue}
                  </P>
                </React.Fragment>
              )}
          </InfoTextDiv>
          {(status === "PARTFUL" || status === "PGF") &&
            this.renderReduceBackorderQty()}
        </InfoDiv>
        <Modal
          okText={"Update backorder quantity"}
          onCancel={() => this.handleCloseReduceModal()}
          onOk={e => this.handleChangeBackorderQty(e)}
          visible={reduceModalIsVisible}
          title={`Change Backorder Qty for ${name}`}
        >
          <div style={{ display: "flex" }}>
            <div className="col-12">
              <form method="post">
                <InputSelect
                  label={"I want to change the backorder quantity to:  "}
                  data={this.getValuesForReductBackorderQtyInputSelect()}
                  value={qtyBackorder}
                  onChange={e => this.handleInputSelectChange(e)}
                  name={"qtyBackorder"}
                />
              </form>
            </div>
          </div>
        </Modal>
      </OrderItemDiv>
    );
  }
}

export default OrderItem;
