export const theme = {
  colorPrimary: "#8ddae6",
  colorPrimaryLight: "#c0ffff",
  colorPrimaryDark: "#5ba8b4",
  colorSecondary: "#fda139",
  colorSecondaryLight: "#ffd26a",
  colorSecondaryDark: "#c57200",
  colorTextGray: "#4C4C4C",
  colorBlack: "#000000",
  colorError: "#e6908d",
  colorLink: "#2874ef",
  fontPrimary: '"Museo Sans Rounded 500", "Open Sans"',
  fontPrimaryFontWeightHeavy: '"Museo Sans Rounded 700", "Open Sans"',
  colorBadgeFulfilled: "#5BC431",
  colorBadgePartiallyFulfilled: "#2A8FF7",
  colorBadgePendingFulfilment: "#c57200",
  colorBadgeDiscontinued: "#A80024",

  // artling styles
  colorBadgeDanger: "#A80024",
  colorBadgeSuccess: "#006D50",
  colorBadgeWarning: "#F28400",
  colorButtonText: "#FFFFFF",
  colorGray: "#666666",
  colorGrayLight: "#dad9d9",
  colorGrayLighter: "#D5D5D6",
  colorGrayTopBar: "#F3F3F3",
  colorGrayMedium: "#afafaf",
  colorNotice: "#D9EDF7",
  colorWhite: "#FFF",
  fontSizeButton: "10pt",
  fontSizeButtonSmall: "8pt",
  fontSizeH1: "17pt",
  fontSizeH1Large: "26pt",
  fontSizeH1Medium: "15pt",
  fontSizeH1MediumSmall: "14pt",
  fontSizeH1Small: "12pt",
  fontSizeH2: "13pt",
  fontSizeH2Large: "19pt",
  fontSizeH2Larger: "21pt",
  fontSizeH2Small: "12pt",
  fontSizeH2Smaller: "10pt",
  fontSizeH2Smallest: "8pt",
  fontSizeLi: "12pt",
  fontSizeLiSmall: "10pt",
  fontSizeP: "12pt",
  fontSizePFineprint: "7pt",
  fontSizePFineprintSmall: "5pt",
  fontSizePLarger: "15pt",
  fontSizePMedium: "11pt",
  fontSizePLarge: "13pt",
  fontSizeMediumSmall: "10.5pt",
  fontSizePSmall: "10pt",
  fontSizePSmaller: "9pt",
  fontSizePSmallest: "8pt",
  fontSizePTiny: "7pt",
  letterSpacingNarrow: "0.025em",
  letterSpacingNormal: "0.03em",
  letterSpacingWide: "0.05em",
  letterSpacingButtonText: "0.1em",

  // old styles
  colorGrayDark: "#4C4C4C",
  colorGrayLightest: "#EFEFEF",
  colorInputLabel: "#5a5a5b",
  colorInfo: "#5bc0de",
  colorWarning: "#f0ad4e",
  colorDanger: "#FF0000",
  colorDangerLight: "#f2dede",
  colorDangerDark: "#c9302c",
  colorDangerBg: "#e47975",
  colorSuccess: "#94c576",
  colorSuccessBright: "rgb(92, 184, 92)",
  colorSuccessLight: "#cdf2b7",
  colorImpersonated: "#966daa",
  fontSerif: '"FrysBaskervilleBT-Roman","EB Garamond",serif',
  fontSansSerif: '"Lato","Helvetica Neue",Helvetica,Arial,sans-serif',
  fontBlogDetailView: "Garamond Pro Regular",
  fontBlogTypewriter: "Courier New",
  fontSizeArtzine: "16px",
  fontSizeArtzineBlockQuote: "18px",
  fontSizeH3: "18px",
  fontSizeH4: "15px",
  fontSizeH5: "14px",
  fontSizeH6: "12px",
  fontSizeInputLarge: "14px",
  fontSizeInput: "13px",
  fontSizeInputLabel: "12pt",
  fontSizeNavigationItem: "13px",
  fontSizeNavigationItemSmall: "10px",
  fontSizeNavigationItemTablet: "14px",
  fontSizeNavigationItemMobile: "16px",
  fontWeightHeavy: "600",
  fontWeightLight: "300",
  fontWeightLighter: "150",
  lineHeightNavigationItemMobile: "1.7em",
  heightInput: "34px",
  heightInputNum: 34,
  heightInputMedium: "30px",
  heightInputSmall: "20px",
  heightInputNumSmall: 20,
  transitionSpeed: 0.3,
  zIndexTeaser: 998,
  zIndexCartPopover: 1001,
  zIndexNavigationUnderlay: 999,
  zIndexNavigationMain: 1000,
  zIndexNavigationSubItems: 1001,
  zIndexAddToCartStickyButton: 1000,
  zIndexDarkBackground: 2000,
  zIndexModal: 1002,
  zIndexModalContainer: 1003,
  zIndexLoading: 1004,
  zIndexSharePopover: 1003,
  zIndexLayoverUnderSharePopover: 1002,
  zIndexNavigationMainSearch: 1001,
  modalWidthSmall: "400",
  modalWidthMedium: "600",
  modalWidthLarge: "800"
};

export const mediaSmDown = "@media (max-width: 767px)";
export const mediaMd = "@media (min-width: 992px) and (max-width: 1199px)";
export const mediaLg = "@media (min-width: 1200px)";

export const hoverPrimary = {
  transition: `color ${theme.transitionSpeed}s ease`,
  ":hover": {
    color: theme.colorPrimary
  },
  "> a": {
    textDecoration: "none",
    ":hover": {
      color: theme.colorPrimary
    }
  }
};

export const hoverGray = {
  transition: `color ${theme.transitionSpeed}s ease`,
  ":hover": {
    color: theme.colorGrayDark
  },
  "> a": {
    textDecoration: "none",
    ":hover": {
      color: theme.colorGrayDark
    }
  }
};

export const hoverPrimaryUnderline = {
  transition: `color ${theme.transitionSpeed}s ease`,
  ":hover": {
    color: theme.colorPrimary
  },
  "> a": {
    textDecoration: "underline",
    ":hover": {
      color: theme.colorPrimary
    }
  }
};

export const hoverPrimaryBG = {
  transition: `background-color ${theme.transitionSpeed}s ease`,
  ":hover": {
    backgroundColor: theme.colorPrimary
  }
};

export const focusInput = {
  border: `1px solid ${theme.colorPrimary}`,
  boxShadow: `0 0 10px ${theme.colorPrimary}`,
  ":focus": {
    outline: "none",
    boxShadow: `0 0 5px ${theme.colorPrimary}`,
    border: `1px solid ${theme.colorPrimary}`
  }
};

export const focusInputLite = {
  ":focus": {
    outline: "none",
    boxShadow: `0 0 5px ${theme.colorPrimary}`,
    border: `1px solid ${theme.colorPrimary}`
  }
};

theme.hoverPrimary = hoverPrimary;
export default theme;
