import React, { Component } from "react";
import { Link } from "react-router-dom";
import Button from "../components/web/buttons/Button.js";
import P from "../components/web/typography/P.js";

import { withAuth } from "../components/utils/index.js";

class OrderSuccessView extends Component {
  handleRedirectToOrderDetailsView() {
    const { orderId, orderDate } = this.props.moreProps.location.state;
    this.props.history.push({
      pathname: `/profile/orders/${orderId}`,
      state: {
        orderId: orderId,
        orderDate: orderDate
        // backorderItems: backorderItems
      }
    });
  }

  render() {
    return (
      <div className="mt-4 col-8" style={{ margin: "0 auto" }}>
        <P>Thank you for your order! </P>
        <P>
          We have received your order and will be in touch with you shortly.
        </P>
        <P className="mb-5">
          You can also check your order details and adjust the order quantities{" "}
          <span
            style={{ cursor: "pointer", color: "blue" }}
            onClick={() => this.handleRedirectToOrderDetailsView()}
          >
            here.
          </span>
        </P>
        <Link
          to={"/products/"}
          style={{ textDecoration: "none", color: "inherit" }}
        >
          <Button className="mx-auto">
            <P className="mb-0" white>
              Continue Shopping
            </P>
          </Button>
        </Link>
      </div>
    );
  }
}

export default withAuth(OrderSuccessView);
