import React, { Component } from "react";
import PropTypes from "prop-types";
import glamorous from "glamorous";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";

import BackTop from "antd/lib/back-top";
import BreadCrumb from "antd/lib/breadcrumb";
import Radio from "antd/lib/radio";
import Notification from "antd/lib/notification";

import { withAuth } from "../components/utils/index.js";

import H1 from "../components/web/typography/H1.js";
import P from "../components/web/typography/P.js";
import OrderItem from "../components/web/Orders/OrderItem.js";
import ModalLoading from "../components/web/modals/ModalLoading.js";
import ErrorMessageGeneral from "../components/web/misc/ErrorMessageGeneral.js";

const ContainerWhite = glamorous.div(props => ({
  backgroundColor: "white",
  padding: "1em",
  width: "100%",
  marginBottom: "1em"
}));
ContainerWhite.displayName = "views.OrderDetailView.ContainerWhite";

const ItemsDiv = glamorous.div(props => ({
  display: "flex",
  flexWrap: "wrap"
}));
ItemsDiv.displayName = "views.OrderDetailView.ItemsDiv";

const ItemDiv = glamorous.div(props => ({
  marginBottom: "1.5em",
  flex: "0 0 30%"
}));
ItemDiv.displayName = "views.OrderDetailView.ItemDiv";

class OrderDetailView extends Component {
  static propTypes = {
    orderId: PropTypes.string,
    orderDate: PropTypes.string
  };

  constructor(props) {
    super(props);
    let jwtToken = localStorage.getItem("id_token");
    this.state = {
      jwtToken: jwtToken,
      data: [],
      orderId: this.props.moreProps.orderId,
      isLoading: true,
      error: null,
      radioButtonValue: "all"
    };
  }

  componentDidMount() {
    this.setState({ isLoading: true });

    this.props.auth
      .fetch(
        `${
          process.env.REACT_APP_BACKEND_BASE_URL
        }/order_platform/get_backorderitems_per_order/?order_id=${
          this.state.orderId
        }`
      )
      .then(data => {
        this.setState({
          data: data,
          isLoading: false
        });
      })
      .catch(error => {
        this.setState({ error: true, isLoading: false });
      });
  }

  renderHeader() {
    return (
      <div>
        <H1 medium className="text-left mt-2 mb-4">
          ORDER DETAILS
        </H1>
      </div>
    );
  }

  renderOrderMainInfo(orderId, orderDate) {
    return (
      <ContainerWhite>
        <div>
          <H1 medium className="text-left mt-2 mb-4">
            ORDER DETAILS
          </H1>
        </div>

        <P small className="mb-1" style={{ flex: 1 }}>
          Order No: {orderId}
        </P>
        <P small className="mb-1" style={{ flex: 1 }}>
          Order Date: {orderDate}
        </P>
      </ContainerWhite>
    );
  }

  getImgSrc(item) {
    let result =
      !!item.product.photo_set &&
      !!item.product.photo_set[0] &&
      item.product.photo_set[0].image;

    return result;
  }

  handleChangeBackorderQty(error) {
    if (error) {
      this.setState({ error: true });
    } else {
      this.props.auth
        .fetch(
          `${
            process.env.REACT_APP_BACKEND_BASE_URL
          }/order_platform/get_backorderitems_per_order/?order_id=${
            this.state.orderId
          }`
        )
        .then(data => {
          this.setState({
            data: data,
            isLoading: false
          });
          Notification["success"]({
            message: "Backorder Qty Updated"
          });
        })
        .catch(error => {
          this.setState({ error: true, isLoading: false });
          Notification["error"]({
            message: "Unable to update Backorder Qty",
            description: "Please try again later"
          });
        });
    }
  }

  renderNoItemsMsg() {
    let result;
    switch (this.state.radioButtonValue) {
      case "ful":
        result = "No Fulfilled Items";
        break;
      case "backorder":
        result = "No Backordered Items";
        break;
      case "discon":
        result = "No Discontinued Items";
        break;
      case "del":
        result = "No Deleted items";
        break;
      default:
        result = "No Items";
    }
    return result;
  }

  renderItems(items) {
    return (
      <ContainerWhite>
        <ItemsDiv className="">
          {items.length === 0 && (
            <P className="mb-0">{this.renderNoItemsMsg()}</P>
          )}
          {items.map(item => (
            <ItemDiv key={item.id} className="col-12 col-md-6 col-lg-4">
              <OrderItem
                cartItemId={item.id}
                imgSrc={this.getImgSrc(item)}
                name={`${item.product.description}
                  (${item.product.code})`}
                qtyOrdered={item.quantity}
                qtyFulfilled={item.qty_fulfilled}
                qtyBackorder={
                  parseInt(item.quantity, 10) - parseInt(item.qty_fulfilled, 10)
                }
                status={item.bi_status}
                changeBackorderQty={error =>
                  this.handleChangeBackorderQty(error)
                }
                packQty={item.product.pack_qty}
                tradePrice={item.product.trade_price}
                tradePriceGst={item.product.trade_price_gst}
              />
            </ItemDiv>
          ))}
        </ItemsDiv>
      </ContainerWhite>
    );
  }

  renderNotice() {
    return (
      <ContainerWhite
        style={{ backgroundColor: "#ffd26a", borderRadius: "10px" }}
        className="mb-4"
      >
        <P className="mb-0" style={{ flex: 1 }} small>
          Quantities for Backordered items can be adjusted.
        </P>
        <P className="mb-0" style={{ flex: 1 }} small>
          To do so, click on the Change Backorder Qty button under each
          backordered item.
        </P>
      </ContainerWhite>
    );
  }

  handleOnChange(e) {
    this.setState({
      radioButtonValue: e.target.value
    });
  }

  renderRadioButtons() {
    return (
      <div className="mb-2">
        <Radio.Group onChange={e => this.handleOnChange(e)} defaultValue="all">
          <Radio value="all">
            <P small style={{ display: "inline" }}>
              All Items
            </P>
          </Radio>
          <Radio value="ful">
            <P small style={{ display: "inline" }}>
              Fulfilled
            </P>
          </Radio>
          <Radio value="backorder">
            <P small style={{ display: "inline" }}>
              Backordered (Pending/Partial Fulfillment)
            </P>
          </Radio>
          <Radio value="del">
            <P small style={{ display: "inline" }}>
              Deleted (by you){" "}
            </P>
          </Radio>
          <Radio value="discon">
            <P small style={{ display: "inline" }}>
              Discontinued
            </P>
          </Radio>
        </Radio.Group>
      </div>
    );
  }

  render() {
    const { orderId, orderDate } = this.props.moreProps;
    const { data, isLoading, error, radioButtonValue } = this.state;

    if (isLoading) {
      return <ModalLoading isVisible />;
    }

    if (error) {
      return <ErrorMessageGeneral />;
    }

    return (
      <React.Fragment>
        <div className="mb-4">
          <BreadCrumb>
            <BreadCrumb.Item>
              <Link to={"/profile/orders/"}>My Orders</Link>
            </BreadCrumb.Item>
            <BreadCrumb.Item>Order</BreadCrumb.Item>
          </BreadCrumb>
        </div>
        <div>
          {/*{this.renderHeader()}*/}
          {this.renderOrderMainInfo(orderId, orderDate)}
          {this.renderNotice()}
          {this.renderRadioButtons()}
          {isLoading === false &&
            !!data &&
            radioButtonValue === "all" &&
            this.renderItems(!!data && data)}
          {isLoading === false &&
            !!data &&
            radioButtonValue === "ful" &&
            this.renderItems(
              !!data && data.filter(item => item.bi_status === "FUL")
            )}
          {isLoading === false &&
            !!data &&
            radioButtonValue === "backorder" &&
            this.renderItems(
              !!data &&
                data.filter(
                  item =>
                    item.bi_status === "PGF" || item.bi_status === "PARTFUL"
                )
            )}
          {isLoading === false &&
            !!data &&
            radioButtonValue === "del" &&
            this.renderItems(
              !!data && data.filter(item => item.bi_status === "DEL")
            )}

          {isLoading === false &&
            !!data &&
            radioButtonValue === "discon" &&
            this.renderItems(
              !!data && data.filter(item => item.bi_status === "DISCON")
            )}

          <BackTop />
        </div>
      </React.Fragment>
    );
  }
}

export default withAuth(withRouter(OrderDetailView));
