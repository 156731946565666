import glamorous from 'glamorous'

// WARNING: Do not turn this component into a React-component
// We are doing `glamorous(ModalFadeInBase)` in other files and this would
// cause a "an unexpected rule cache miss occurred. This is probably a sign of
// multiple glamor instances in your app."
const ModalFadeInBase = glamorous.div(
  props => ({
    alignItems: 'center',
    backgroundColor: props.theme.colorSuccess,
    color: 'white',
    cursor: 'default',
    display: 'block',
    fontFamily: props.theme.fontSerif,
    fontWeight: '300',
    height: 'auto',
    padding: '10px',
    textAlign: 'center',
    width: '100%',
    borderRadius: '3px',
  }),
  props => {
    if (!props.show) {
      return {
        height: '0px',
        opacity: 0,
        overflowY: 'hidden',
        padding: '0px',
        transition: 'opacity 0.2s ease',
      }
    }
    return {
      opacity: 1,
      transition: 'opacity 1s ease',
    }
  }
)
ModalFadeInBase.displayName = 'web.modals.ModalFadeInBase'

export default ModalFadeInBase
