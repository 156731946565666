import React, { Component } from "react";
import { Link } from "react-router-dom";
import glamorous from "glamorous";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import queryString from "query-string";

import Pagination from "antd/lib/pagination";
import BackTop from "antd/lib/back-top";

import { withAuth } from "../components/utils/index.js";

import Product from "../components/web/products/Product.js";
import P from "../components/web/typography/P.js";
import Button from "../components/web/buttons/Button.js";
import ModalLoading from "../components/web/modals/ModalLoading.js";
import ErrorMessageGeneral from "../components/web/misc/ErrorMessageGeneral.js";

const ProductContainer = glamorous.div(props => ({
  marginTop: "2em",
  marginBottom: "8em",
  "@media(min-width: 767px)": {
    marginBottom: "4em"
  }
}));
ProductContainer.displayName = "views.OrdersView.ProductContainer";

class ProductGridView extends Component {
  static displayName = "views.ProductGridView";

  constructor(props) {
    super(props);
    let jwtToken = localStorage.getItem("id_token");

    this.state = {
      jwtToken: jwtToken,
      products: [],
      numOfProducts: 0,
      isLoading: false,
      error: null
    };
  }

  fetchProducts(api) {
    this.setState({ isLoading: true });
    this.props.auth
      .fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}${api}`)
      .then(data =>
        this.setState({
          products: data.results,
          numOfProducts: data.count,
          isLoading: false
        })
      )
      .catch(error => {
        console.log("error");
        this.setState({ error: true, isLoading: false });
      });
    window.scrollTo(0, 0);
  }

  componentDidMount() {
    this.setState({ isLoading: true });
    // if this.props.match.params.category !== undefined, load category where this.props.match.params.category
    // if this.props.path.indexOf('/products/search/') > -1, searchTerm = queryString.parse(this.props.location.search).search
    // else load all products
    let pathname = this.props.location.pathname;
    let page = queryString.parse(this.props.location.search).page;

    let category = this.props.match.params.category;
    let isCatchMeWhileYouCan = category === "Catch Me While You Can";

    let search = queryString.parse(this.props.location.search).search;

    if (this.props)
      if (pathname === "/products/" && page === undefined) {
        this.fetchProducts("/order_platform/get_all_products/");
      } else if (pathname === "/products/" && page !== undefined) {
        this.fetchProducts(`/order_platform/get_all_products/?page=${page}`);
      } else if (
        category !== undefined &&
        !isCatchMeWhileYouCan &&
        page === undefined
      ) {
        this.fetchProducts(
          `/order_platform/get_product_by_cat/?category=${category}`
        );
      } else if (
        category !== undefined &&
        !isCatchMeWhileYouCan &&
        page !== undefined
      ) {
        this.fetchProducts(
          `/order_platform/get_product_by_cat/?category=${category}&page=${page}`
        );
      } else if (
        category !== undefined &&
        isCatchMeWhileYouCan &&
        page === undefined
      ) {
        this.fetchProducts(
          `/order_platform/get_all_discon_with_stock_products/`
        );
      } else if (
        category !== undefined &&
        isCatchMeWhileYouCan &&
        page !== undefined
      ) {
        this.fetchProducts(
          `/order_platform/get_all_discon_with_stock_products/?page=${page}`
        );
      } else if (search !== undefined && page === undefined) {
        this.fetchProducts(`/order_platform/search_product/?search=${search}`);
      } else if (search !== undefined && page !== undefined) {
        this.fetchProducts(
          `/order_platform/search_product/?search=${search}&page=${page}`
        );
      }
  }

  componentDidUpdate(prevProps) {
    let prevPathname = prevProps.location.pathname;
    let currPathname = this.props.location.pathname;

    let prevPage = queryString.parse(prevProps.location.search).page;
    let currPage = queryString.parse(this.props.location.search).page;

    let prevCategory = prevProps.match.params.category;
    let currCategory = this.props.match.params.category;

    let prevSearch = queryString.parse(prevProps.location.search).search;
    let currSearch = queryString.parse(this.props.location.search).search;

    if (prevPathname !== currPathname && currPathname === "/products/") {
      this.fetchProducts(`/order_platform/get_all_products/`);
    }

    if (prevSearch !== currSearch && currSearch !== undefined) {
      // console.log("trigger search");
      this.fetchProducts(
        `/order_platform/search_product/?search=${currSearch}`
      );
    } else if (
      prevSearch === currSearch &&
      currSearch !== undefined &&
      prevPage !== currPage
    ) {
      // console.log("same search, page change");
      this.fetchProducts(
        `/order_platform/search_product/?search=${currSearch}&page=${currPage}`
      );
    }

    if (
      prevCategory !== currCategory &&
      currCategory !== undefined &&
      this.props.match.params.category !== "Catch Me While You Can"
    ) {
      this.fetchProducts(
        `/order_platform/get_product_by_cat/?category=${currCategory}`
      );
    } else if (
      prevCategory !== currCategory &&
      currCategory !== undefined &&
      this.props.match.params.category === "Catch Me While You Can"
    ) {
      this.fetchProducts(`/order_platform/get_all_discon_with_stock_products/`);
    }

    if (
      currCategory === undefined &&
      prevCategory === currCategory &&
      prevCategory === undefined &&
      currPage !== prevPage &&
      currSearch === undefined
    ) {
      // console.log("page change, no category");
      let query = currPage === undefined ? "" : `?page=${currPage}`;
      this.fetchProducts(`/order_platform/get_all_products/${query}`);
    } else if (
      currCategory !== undefined &&
      prevCategory === currCategory &&
      !!currCategory &&
      currPage !== prevPage &&
      this.props.match.params.category !== "Catch Me While You Can"
    ) {
      // console.log("page change, category stay same, category is not CMWYC");
      this.fetchProducts(
        `/order_platform/get_product_by_cat/?category=${currCategory}&page=${currPage}`
      );
    } else if (
      currCategory !== undefined &&
      prevCategory === currCategory &&
      !!currCategory &&
      currPage !== prevPage &&
      this.props.match.params.category === "Catch Me While You Can"
    ) {
      // console.log("page change, category stay same, category is CMWYC");
      this.fetchProducts(
        `/order_platform/get_all_discon_with_stock_products/?page=${currPage}`
      );
    }
  }

  handlePaginationChange(page, pageSize) {
    let currPathname = this.props.location.pathname;
    let currSearch = queryString.parse(this.props.location.search).search;
    if (currSearch === undefined) {
      this.props.history.push(`${currPathname}?page=${page}`);
    } else if (!!currSearch) {
      this.props.history.push(
        `${currPathname}?search=${currSearch}&page=${page}`
      );
    }
  }

  render() {
    const { isLoading, error } = this.state;
    let products = this.state.products;
    let numOfProducts = this.state.numOfProducts;

    let currentPage = queryString.parse(this.props.location.search).page;
    if (currentPage === undefined) {
      currentPage = 1;
    }
    if (isLoading) {
      return <ModalLoading isVisible />;
    }

    if (error) {
      return <ErrorMessageGeneral />;
    }

    return (
      <div>
        {numOfProducts === 0 && (
          <div>
            <P className="mt-5">
              Sorry, we couldn't find what you were looking for. <br /> <br />{" "}
              Let us know what you're looking for at{" "}
              <a href="mailto:orders@jellycat.sg?Subject=Enquiry" target="_top">
                orders@jellycat.sg
              </a>
            </P>
            <Link
              to={"/products/"}
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <Button className="mx-auto">Back to all Jellycat Products</Button>
            </Link>
          </div>
        )}
        {numOfProducts > 0 && (
          <ProductContainer>
            <div className="row">
              {!!products &&
                products.map((item, index) => (
                  <div className="col-6 col-sm-6 col-lg-3" key={index}>
                    <Product
                      status={item.prod_status}
                      id={item.id}
                      productName={item.description}
                      code={item.code}
                      height={item.height}
                      tradePrice={item.trade_price}
                      suggestedRetailPrice={item.suggested_retail_price}
                      category={item.category}
                      barcode={item.barcode}
                      src={
                        !!item.photo_set &&
                        !!item.photo_set[0] &&
                        item.photo_set[0].image
                      }
                      packQty={item.pack_qty}
                    />
                  </div>
                ))}
            </div>
            <div>&nbsp;</div>
            <div className="row" style={{ paddingBottom: "50px" }}>
              <Pagination
                style={{ width: "100%" }}
                total={this.state.numOfProducts}
                pageSize={20}
                onChange={e => this.handlePaginationChange(e, 20)}
                current={parseInt(currentPage, 10)}
              />
            </div>
          </ProductContainer>
        )}
        <BackTop />
      </div>
    );
  }
}
const mapStateToProps = state => {
  return { showCartPopover: state.cart.showCartPopover };
};

export default withAuth(withRouter(connect(mapStateToProps)(ProductGridView)));
