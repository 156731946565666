import React from "react";
import PropTypes from "prop-types";
import glamorous from "glamorous";

const ButtonButton = glamorous.button(props => {
  const ButtonColor = {
    default: props.theme.colorPrimary,
    secondary: props.theme.colorSecondary,
    white: props.theme.colorWhite,
    gray: props.theme.colorGrayLight
  };
  const ButtonColorActive = {
    default: props.theme.colorPrimaryLight,
    secondary: props.theme.colorSecondaryLight,
    white: props.theme.colorGrayLightest,
    gray: props.theme.colorGray
  };
  const ButtonColorHover = {
    default: props.theme.colorPrimaryDark,
    secondary: props.theme.colorSecondaryDark,
    white: props.theme.colorGrayLight,
    gray: props.theme.colorGray
  };
  const ButtonTextColor = {
    white: props.theme.colorGray
  };
  const ButtonBorder = {
    white: `1px solid ${props.theme.colorGrayLight}`
  };
  return {
    backgroundColor: ButtonColor[props.buttonType] || props.theme.colorPrimary,
    alignItems: "center",
    border: ButtonBorder[props.buttonType] || "none",
    borderRadius: "20px",
    color: ButtonTextColor[props.buttonType] || props.theme.colorWhite,
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    padding: "0.5em 1em",
    fontFamily: props.theme.fontPrimary,
    fontWeight: props.theme.fontWeightHeavy,
    letterSpacing: props.theme.letterSpacingButtonText,
    textTransform: props.noTextTransform ? "none" : "uppercase",
    whiteSpace: "nowrap",
    width: props.isFullWidth ? "100%" : "auto",
    transition: "0.3s",
    ":disabled": {
      cursor: "not-allowed",
      opacity: 0.6
    },
    ":hover": {
      backgroundColor:
        ButtonColorHover[props.buttonType] || props.theme.colorPrimaryDark
    },
    ":focus": {
      outlineStyle: "none !important"
    },
    ":active": {
      backgroundColor:
        ButtonColorActive[props.buttonType] || props.theme.colorPrimaryLight
    }
  };
});
ButtonButton.displayName = "web.buttons.Button.ButtonButton";

class Button extends React.Component {
  static displayName = "core.buttons.Button";

  static propTypes = {
    disabled: PropTypes.bool,
    isLoading: PropTypes.bool,
    loadingText: PropTypes.string,
    onClick: PropTypes.func
  };

  static defaultProps = {
    disabled: false,
    isLoading: false,
    loadingText: undefined,
    onClick: undefined
  };

  render() {
    const {
      className,
      disabled,
      isFullWidth,
      isLoading,
      loadingText,
      onClick,
      formDisabledAfterSubmit,
      buttonTextAfterSubmit,
      ...otherProps
    } = this.props;

    let displayText = this.props.children;
    if (isLoading) {
      displayText = "Loading...";
    }

    return (
      <ButtonButton
        className={className}
        disabled={disabled || isLoading}
        isFullWidth={isFullWidth}
        onClick={onClick}
        {...otherProps}
      >
        {displayText}
      </ButtonButton>
    );
  }
}

export default Button;
