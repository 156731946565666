import React, { Component } from "react";
import glamorous from "glamorous";

import CartRowQuantity from "./CartRowQuantity.js";
import P from "../typography/P.js";
import Button from "../buttons/Button.js";
import ImageResponsive from "../images/ImageResponsive.js";
import ImageFixedProportions from "../images/ImageFixedProportions.js";

import ImageProductPlaceholderPng from "../../../static/ImageProductPlaceholder.png";
import IconCloseSvg from "../../../static/IconClose.svg";

const Container = glamorous.div(props => ({
  zIndex: props.showCancelUpdate ? 2 : 0,
  backgroundColor: props.showCancelUpdate ? "white" : "auto",
  position: "relative",
  transition: "background-color 0.3s ease,"
}));

const ItemDiv = glamorous.div(props => ({
  fontFamily: props.fontPrimary,
  display: "flex",
  paddingTop: "1em",
  paddingBottom: "1em"
}));
ItemDiv.displayName = "web.cart.CartRow.ItemDiv";

const CancelUpdateDiv = glamorous.div(props => ({
  backgroundColor: "white",
  zIndex: props.isVisible ? 1001 : -1,
  position: "relative",
  padding: props.isVisible ? "0.5em" : 0,
  height: props.isVisible ? "70px" : "0px",
  transform: props.isVisible ? "translateY(0px)" : "translateY(-70px)",
  transition:
    "transform 0.3s ease-in-out, height 0.3s ease-in-out, background-color 0.3s ease"
}));
CancelUpdateDiv.displayName = "web.cart.CartRow.CancelUpdateDiv";

const CancelUpdateInnerDiv = glamorous.div(props => ({
  width: "95%",
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
  padding: "0.5em",
  borderTop: `1px solid ${props.theme.colorGrayLight}`
}));
CancelUpdateInnerDiv.displayName = "web.cart.CartRow.CancelUpdateInnerDiv";

const IconCloseDiv = glamorous.div(props => ({
  cursor: "pointer"
}));
IconCloseDiv.displayName = "web.cart.CartRow.IconCloseDiv";

class CartRow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showCancelUpdate: false,
      isCancelClicked: false
    };
  }

  componentWillUpdate(nextProps) {
    if (
      nextProps.overlayIsVisible === false &&
      this.state.showCancelUpdate === true
    ) {
      this.setState({ showCancelUpdate: false, isCancelClicked: false });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.item.quantity !== this.props.item.quantity) {
      this.setState({
        quantity: this.props.item.quantity
      });
    }
  }

  handleOnChange(value) {
    this.setState({ showCancelUpdate: true, quantity: value });
    if (this.props.onChange) {
      this.props.onChange();
    }
  }

  handleCancel() {
    this.setState({ showCancelUpdate: false, isCancelClicked: true });
    if (this.props.onCancel) {
      this.props.onCancel();
    }
  }

  handleUpdate() {
    const { item } = this.props;
    this.setState({ showCancelUpdate: false });
    if (this.props.onUpdate) {
      this.props.onUpdate(this.state.quantity, item.id);
    }
  }

  handleRemoveCartItem(item) {
    if (this.props.onRemoveCartItem) {
      this.props.onRemoveCartItem(item.id);
    }
  }

  render() {
    const { item } = this.props;
    const { showCancelUpdate, isCancelClicked } = this.state;

    return (
      <div>
        <Container showCancelUpdate={showCancelUpdate}>
          <ItemDiv>
            <div className="col-5 col-sm-4 col-md-3">
              <ImageFixedProportions
                src={
                  !!item.product.photo_set &&
                  !!item.product.photo_set[0] &&
                  item.product.photo_set[0].image
                }
                placeholderPng={ImageProductPlaceholderPng}
              />
            </div>
            <div className="text-left col-6 col-sm-7 col-md-8">
              <P fontWeightHeavy fontColorAlert larger className="mb-2">
                ${item.product.trade_price}
              </P>
              <P className="mb-1">
                {item.product.description}({item.product.code})
              </P>
              <P className="mb-0">Height: {item.product.height}cm</P>
              <CartRowQuantity
                onChange={value => this.handleOnChange(value)}
                quantity={item.quantity}
                packQty={item.product.pack_qty}
                isCancelClicked={isCancelClicked}
              />
            </div>
            <IconCloseDiv onClick={() => this.handleRemoveCartItem(item)}>
              <ImageResponsive
                src={IconCloseSvg}
                isIcon
                style={{ left: "3px" }}
              />
            </IconCloseDiv>
          </ItemDiv>
        </Container>
        <CancelUpdateDiv isVisible={showCancelUpdate}>
          <CancelUpdateInnerDiv className="mx-auto">
            <P
              className="mb-0"
              onClick={() => this.handleCancel()}
              style={{ cursor: "pointer" }}
            >
              cancel
            </P>
            <div className="mr-2 ml-2">&nbsp;</div>
            <Button noTextTransform onClick={() => this.handleUpdate()}>
              <P className="mb-0">update</P>
            </Button>
          </CancelUpdateInnerDiv>
        </CancelUpdateDiv>
      </div>
    );
  }
}

export default CartRow;
