import React, { Component } from "react";
import PropTypes from "prop-types";
import glamorous from "glamorous";
import { withRouter } from "react-router";
import queryString from "query-string";

import BackTop from "antd/lib/back-top";
import Pagination from "antd/lib/pagination";

import { withAuth } from "../components/utils/index.js";

import OrderItemBadge from "../components/web/Orders/OrderItemBadge.js";
import ImageFixedProportions from "../components/web/images/ImageFixedProportions.js";
import H1 from "../components/web/typography/H1.js";
import P from "../components/web/typography/P.js";
import ModalLoading from "../components/web/modals/ModalLoading.js";
import ErrorMessageGeneral from "../components/web/misc/ErrorMessageGeneral.js";

import ImageProductPlaceholderPng from "../static/ImageProductPlaceholder.png";

const ContainerWhite = glamorous.div(props => ({
  backgroundColor: "white",
  padding: "1em",
  width: "100%",
  marginBottom: "1em"
}));
ContainerWhite.displayName = "views.OrderDetailView.ContainerWhite";

const ItemsDiv = glamorous.div(props => ({
  display: "flex",
  flexWrap: "wrap"
}));
ItemsDiv.displayName = "views.OrderDetailView.ItemsDiv";

const ItemDiv = glamorous.div(props => ({
  marginBottom: "1.5em",
  flex: "0 0 30%"
}));
ItemDiv.displayName = "views.OrderDetailView.ItemDiv";

class BackordersView extends Component {
  static propTypes = {
    orderId: PropTypes.string
  };

  constructor(props) {
    super(props);
    let jwtToken = localStorage.getItem("id_token");
    this.state = {
      jwtToken: jwtToken,
      data: [],
      isLoading: true,
      error: null
    };
  }

  fetchBackorders(api) {
    this.setState({ isLoading: true });
    this.props.auth
      .fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}${api}`)
      .then(data => {
        this.setState({
          data: data.results,
          dataCount: data.count,
          isLoading: false
        });
      })
      .catch(error => {
        this.setState({ error: true, isLoading: false });
      });
    window.scrollTo(0, 0);
  }

  componentDidMount() {
    this.setState({ isLoading: true });

    let page = queryString.parse(this.props.location.search).page || 1;

    this.fetchBackorders(`/order_platform/get_backorder_items/?page=${page}`);
  }

  componentDidUpdate(prevProps) {
    let prevPage = queryString.parse(prevProps.location.search).page;
    let currPage = queryString.parse(this.props.location.search).page;
    if (prevPage !== currPage) {
      this.fetchBackorders(
        `/order_platform/get_backorder_items/?page=${currPage}`
      );
    }
  }

  renderHeader() {
    return (
      <div>
        <H1 medium className="text-left mt-2 mb-4">
          ALL BACKORDERED ITEMS
        </H1>
      </div>
    );
  }

  getImgSrc(item) {
    let result =
      !!item.product &&
      !!item.product.photo_set &&
      !!item.product.photo_set[0] &&
      item.product.photo_set[0].image;

    return result;
  }

  renderEmptyBackorders() {
    return <P>You have no items on backorder.</P>;
  }

  getTotalBackordersValue(data) {
    let results = 0;
    let filteredData = data.filter(
      item => item.bi_status === "PGF" || item.bi_status === "PARTFUL"
    );
    filteredData.forEach(
      item =>
        (results +=
          (item.quantity - item.qty_fulfilled) * item.product.trade_price)
    );
    return results.toFixed(2);
  }

  handlePaginationChange(page, pageSize) {
    let currPathname = this.props.location.pathname;
    this.props.history.push(`${currPathname}?page=${page}`);
  }

  renderBackorders(data) {
    const { dataCount } = this.state;
    let currentPage = queryString.parse(this.props.location.search).page || 1;

    return (
      <React.Fragment>
        <ContainerWhite>
          <P className="mb-0">
            Total Value: ${this.getTotalBackordersValue(data)}
          </P>
        </ContainerWhite>
        {data.map(
          item =>
            item.bi_status !== "DISCON" &&
            item.bi_status !== "DEL" && (
              <ContainerWhite
                style={{ display: "flex", flexWrap: "wrap" }}
                key={item.id}
              >
                <div className="col-5 col-md-3">
                  <OrderItemBadge status={item.bi_status} />
                  <ImageFixedProportions
                    src={this.getImgSrc(item)}
                    placeholderPng={ImageProductPlaceholderPng}
                  />
                </div>
                <div className="col-12 col-md-9">
                  <P className="mb-1" bold>
                    {`${item.product.description} (${item.product.code})`}
                  </P>
                  <P className="mb-1">Qty Ordered: {item.quantity}</P>
                  <P className="mb-1" danger>
                    Backorder Qty: {item.quantity - item.qty_fulfilled}
                  </P>
                  <hr />
                  <P className="mb-1">Order ID: {item.order_id}</P>
                  <P className="mb-1">Order Date: {item.order_date}</P>
                </div>
              </ContainerWhite>
            )
        )}
        <div>&nbsp;</div>
        <div
          className="row"
          style={{ paddingBottom: "50px", textAlign: "center" }}
        >
          <Pagination
            style={{ width: "100%" }}
            total={dataCount}
            pageSize={20}
            onChange={e => this.handlePaginationChange(e, 20)}
            current={parseInt(currentPage, 10)}
          />
        </div>
      </React.Fragment>
    );
  }

  render() {
    const { data, isLoading, error } = this.state;

    if (isLoading) {
      return <ModalLoading isVisible />;
    }

    if (error) {
      return <ErrorMessageGeneral />;
    }

    return (
      <div>
        {this.renderHeader()}
        {data.length === 0 && this.renderEmptyBackorders()}
        {data.length > 0 &&
          data.filter(
            item => item.bi_status === "PGF" || item.bi_status === "PARTFUL"
          ).length === 0 &&
          this.renderEmptyBackorders()}
        {isLoading === false &&
          data.length > 0 &&
          data.filter(
            item => item.bi_status === "PGF" || item.bi_status === "PARTFUL"
          ).length > 0 &&
          this.renderBackorders(data)}
        <BackTop />
      </div>
    );
  }
}

export default withAuth(withRouter(BackordersView));
