import React, { Component } from "react";
import glamorous from "glamorous";
import serialize from "form-serialize";
import { Link } from "react-router-dom";

import ImageJellycatLogoPng from "../static/ImageJellycatLogo.png";
import ImageFixedProportions from "../components/web/images/ImageFixedProportions.js";
import InputText from "../components/web/inputs/InputText.js";
import Button from "../components/web/buttons/Button.js";
import P from "../components/web/typography/P.js";
import ModalFadeInForForms from "../components/web/modals/ModalFadeInForForms.js";
import ModalLoading from "../components/web/modals/ModalLoading.js";

import ImageJellycatLogoPlaceholderPng from "../static/ImageJellycatLogoPlaceholder.png";

const Container = glamorous.div(props => ({
  height: "100vh",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "flex-start",
  fontFamily: props.theme.fontPrimary
}));
Container.displayName = "views.LoginView.Container";

class ForgotPasswordView extends Component {
  static displayName = "views.ForgotPasswordView";

  constructor(props) {
    super(props);
    this.state = {
      email: "",
      formErrors: "",
      isLoading: false
    };
  }

  handleSubmit(e) {
    e.preventDefault();
    const formValues = serialize(this.form, { hash: true });

    this.setState({ isLoading: true });
    if (formValues.email === undefined) {
      this.setState({
        formErrors: {
          formError: "Please fix the errors below",
          emailFormError: "Email cannot be empty"
        },
        isLoading: false
      });
    } else {
      fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/auth/password_reset/`, {
        method: "POST",
        headers: new Headers({
          Accept: "application/json",
          "Content-Type": "application/x-www-form-urlencoded"
        }),
        body: `email=${formValues.email}`
      })
        .then(results => {
          if (results.status === 200) {
            this.props.history.push("/password/reset/instructions/");
            this.setState({ isLoading: false });
          } else {
            this.setState({
              formErrors: {
                formError: "Please fix the errors below",
                emailFormError:
                  "There is no active user associated with this email."
              },
              isLoading: false
            });
          }
          return results;
        })
        .catch(error => {
          console.error(error);
        });
    }
  }

  render() {
    const { formErrors, isLoading } = this.state;

    if (isLoading) {
      return <ModalLoading isVisible />;
    }

    return (
      <Container className="mt-5">
        <div className="col-10 col-md-8 col-lg-6">
          <div style={{ width: "300px", margin: "0 auto" }}>
            <ImageFixedProportions
              src={ImageJellycatLogoPng}
              placeholderPng={ImageJellycatLogoPlaceholderPng}
            />
          </div>

          <div>&nbsp;</div>
          <div className="mt-2">
            <ModalFadeInForForms
              formErrors={formErrors}
              errorMsg={formErrors.formError}
            />
          </div>

          <div className="mt-3" style={{ width: "100%" }}>
            <form
              method="post"
              action="."
              onSubmit={e => this.handleSubmit(e)}
              ref={ref => (this.form = ref)}
            >
              <InputText
                labelName="Email"
                placeholder="Email Address"
                inputName="email"
                type="email"
                error={formErrors.emailFormError}
              />
              <Link to="/" className="text-left">
                <P small secondary underline>
                  Back to Login
                </P>
              </Link>
              <Button isFullWidth className="mt-3">
                Reset my password
              </Button>
            </form>
          </div>
        </div>
      </Container>
    );
  }
}

export default ForgotPasswordView;
