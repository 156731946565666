import React, { Component } from "react";
import glamorous from "glamorous";
import { withRouter } from "react-router";
import { withAuth } from "../components/utils/index.js";

import OrdersView from "./OrdersView.js";
import BackordersView from "./BackordersView.js";
import OrderDetailView from "./OrderDetailView.js";

import NavigationProfile from "../components/web/navigation/NavigationProfile.js";
import FormProfileMyDetails from "../components/web/forms/FormProfileMyDetails.js";
import FormProfileChangePassword from "../components/web/forms/FormProfileChangePassword.js";
import H1 from "../components/web/typography/H1.js";

const DivLeft = glamorous.div(props => ({}));
DivLeft.displayName = "views.ProfileView.DivLeft";

const DivRight = glamorous.div(props => ({
  backgroundColor: props.theme.colorGrayTopBar,
  height: "auto",
  paddingTop: "2em",
  paddingBottom: "2em"
}));
DivRight.displayName = "views.ProfileView.DivRight";

const Container = glamorous.div(props => ({
  "@media(min-width: 767px)": {
    display: "flex"
  }
}));
Container.displayName = "views.ProfileView.Container";

class ProfileView extends Component {
  componentDidUpdate() {
    if (!this.props.auth.loggedIn()) {
      //TODO: need to change this url to production url
      window.location.href = process.env.REACT_APP_FRONTEND_URL;
    }
  }
  renderAccountOverview() {
    return (
      <div
        style={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <H1 className="text-center">Welcome to your account</H1>
      </div>
    );
  }

  render() {
    let pathName = this.props.location.pathname;
    let locationState = this.props.location.state;

    return (
      <Container className="container">
        <DivLeft className="col-12 col-md-3 mt-4">
          <NavigationProfile history={this.props.history} />
        </DivLeft>
        <DivRight className="col-12 col-md-9 mt-4 text-left">
          <div
            style={{
              width: "90%",
              height: "100%"
            }}
            className="mx-auto"
          >
            {(pathName === "/profile/" ||
              pathName === "/profile/account-overview/") &&
              this.renderAccountOverview()}
            {pathName === "/profile/orders/" && (
              <OrdersView history={this.props.history} />
            )}
            {pathName === "/profile/backorders/" && <BackordersView />}

            {!!locationState &&
              !!locationState.orderId && (
                <OrderDetailView
                  orderId={locationState.orderId}
                  orderDate={locationState.orderDate}
                  backorderItems={locationState.backorderItems}
                />
              )}
            {pathName === "/profile/details/" && <FormProfileMyDetails />}
            {pathName === "/profile/change-password/" && (
              <FormProfileChangePassword />
            )}
          </div>
        </DivRight>
      </Container>
    );
  }
}

export default withAuth(withRouter(ProfileView));
