import glamorous from 'glamorous'

const InputLabel = glamorous.label(props => ({
  fontFamily: props.theme.fontPrimary,
  display: 'block',
  margin: '0px',
}))
InputLabel.displayName = 'web.inputs.InputLabel'

export default InputLabel
