import React, { Component } from "react";
import P from "../typography/P.js";

class ErrorMessageGeneral extends Component {
  render() {
    return (
      <div className="mt-4">
        <P>
          Sorry, something seems to have gone wrong :( <br />
          <br /> Please contact{" "}
          <a href="mailto:orders@jellycat.com" target="_top">
            orders@jellycat.com
          </a>{" "}
          for further assistance.
        </P>
      </div>
    );
  }
}

export default ErrorMessageGeneral;
