import React, { Component } from 'react'
import PropTypes from 'prop-types'
import glamorous from 'glamorous'

const Div = glamorous.div(props => ({
  position: 'relative',
  '&::before': {
    content: ' ',
    position: 'absolute',
    opacity: 0,
    width: '50px',
    height: '50px',
    top: '-9px',
    left: '-10px',
    backgroundColor: props.isClicked
      ? props.theme.colorGrayLight
      : props.theme.colorGrayTopBar,
    borderRadius: '50%',
    transform: 'scale(0)',
    transitionDuration: '0.15s',
    transitionTimingFunction: 'cubic-bezier(0.4,0.0,0.2,1)',
    transitionProperty: 'transform, opacity',
    zIndex: 0,
  },
  ':hover::before': {
    opacity: 1,
    transform: 'scale(1)',
    zIndex: 0,
  },
}))
Div.displayName = 'web.images.ImageResponsive.img'

const ImageResponsiveImg = glamorous.img(
  () => ({
    width: '100%',
    height: 'auto',
    position: 'relative',
  }),
  props =>
    props.isIcon && {
      cursor: 'pointer',
      zIndex: '1',
    }
)
ImageResponsiveImg.displayName = 'web.images.ImageResponsive.ImageResponsiveImg'

class ImageResponsive extends Component {
  static displayName = 'web.images.ImageResponsive'

  static propTypes = {
    isIcon: PropTypes.bool,
  }

  static defaultProps = {
    isIcon: false,
  }

  constructor(props) {
    super(props)
    this.state = {
      iconIsClicked: false,
    }
  }

  handleIconMouseDown() {
    this.setState({
      iconIsClicked: true,
    })
  }

  handleIconMouseUp() {
    this.setState({
      iconIsClicked: false,
    })
  }

  render() {
    const { isIcon, style, src } = this.props
    const { iconIsClicked } = this.state

    return (
      <div>
        {!isIcon && <ImageResponsiveImg style={{ ...style }} src={src} />}
        {!!isIcon && (
          <Div
            onMouseDown={() => this.handleIconMouseDown()}
            onMouseUp={() => this.handleIconMouseUp()}
            isClicked={iconIsClicked}
          >
            <ImageResponsiveImg src={src} style={{ ...style }} />
          </Div>
        )}
      </div>
    )
  }
}

export default ImageResponsive
