import React, { Component } from "react";
import serialize from "form-serialize";
import Cookies from "js-cookie";

import { withAuth } from "../../utils/index.js";

import ModalFadeInForForms from "../modals/ModalFadeInForForms.js";
import InputText from "../inputs/InputText.js";
import Button from "../buttons/Button.js";

class FormProfileChangePassword extends Component {
  constructor(props) {
    super(props);
    let jwtToken = localStorage.getItem("id_token");

    this.state = {
      jwtToken: jwtToken,
      formErrors: "",
      isSuccesfullyChanged: false,
      formKey: 0,
      error: null,
      isLoading: false
    };
  }

  handleSubmit(e) {
    e.preventDefault();
    let csrfToken = Cookies.get("csrftoken");

    const serializedFormValues = serialize(this.form, { hash: true });
    const oldPassword = serializedFormValues.oldPassword;
    const newPassword = serializedFormValues.newPassword;
    if (newPassword === undefined && oldPassword === undefined) {
      this.setState({
        formErrors: {
          oldPasswordFormError: "Password incorrect",
          newPasswordFormError: "New password cannot be blank"
        },
        isSuccesfullyChanged: false,
        formKey: this.state.formKey + 1
      });
    } else if (newPassword === undefined && oldPassword !== undefined) {
      this.setState({
        formErrors: {
          newPasswordFormError: "New password cannot be blank",
          isSuccesfullyChanged: false
        },
        formKey: this.state.formKey + 1
      });
    } else {
      this.setState({ isLoading: true });
      this.props.auth
        .fetch(
          `${process.env.REACT_APP_BACKEND_BASE_URL}/auth/password_change/`,
          {
            method: "PUT",
            body: `old_password=${oldPassword}&new_password=${newPassword}&csrfmiddlewaretoken=${csrfToken}`,
            credentials: "include"
          },
          {
            "Content-Type": "application/x-www-form-urlencoded",
            "x-csrftoken": csrfToken
          }
        )
        .then(data =>
          this.setState({
            formErrors: {},
            formKey: this.state.formKey + 1,
            isSuccesfullyChanged: true,
            isLoading: false
          })
        )
        .catch(error => {
          this.setState({
            formErrors: {
              oldPasswordFormError: "Password incorrect",
              newPasswordFormError: "",
              isSuccesfullyChanged: false
            },
            formKey: this.state.formKey + 1,
            error: true,
            isLoading: false
          });
        });
    }
  }

  render() {
    const { formErrors, isSuccesfullyChanged } = this.state;
    return (
      <div>
        <form
          key={this.state.formKey}
          method="post"
          action="."
          onSubmit={e => this.handleSubmit(e)}
          ref={ref => (this.form = ref)}
        >
          <InputText
            labelName="Old Password"
            inputName="oldPassword"
            type="password"
            error={formErrors.oldPasswordFormError}
          />
          <InputText
            labelName="New Password"
            type="password"
            inputName="newPassword"
            error={formErrors.newPasswordFormError}
          />
          <Button isFullWidth className="mt-3">
            Update My Password
          </Button>
        </form>

        <div className="mt-2 mb-2">
          <ModalFadeInForForms
            formErrors={formErrors}
            errorMsg={formErrors.formError}
            showSuccess={isSuccesfullyChanged}
            successMsg={"Password successfully changed"}
          />
        </div>
      </div>
    );
  }
}

export default withAuth(FormProfileChangePassword);
