import React, { Component } from "react";
import glamorous from "glamorous";
import { Link } from "react-router-dom";
import P from "../components/web/typography/P.js";
import Button from "../components/web/buttons/Button.js";

import ImageJellycatLogoPng from "../static/ImageJellycatLogo.png";
import ImageFixedProportions from "../components/web/images/ImageFixedProportions.js";

import ImageJellycatLogoPlaceholderPng from "../static/ImageJellycatLogoPlaceholder.png";

const Container = glamorous.div(props => ({
  height: "100vh",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "flex-start",
  fontFamily: props.theme.fontPrimary
}));
Container.displayName = "views.PasswordResetInstructionsView.Container";

class PasswordResetInstructionsView extends Component {
  static displayName = "views.PasswordResetInstructionsView";
  render() {
    return (
      <Container className="mt-5">
        <div className="col-10 col-md-8 col-lg-6">
          <div style={{ width: "300px", margin: "0 auto" }}>
            <ImageFixedProportions
              src={ImageJellycatLogoPng}
              placeholderPng={ImageJellycatLogoPlaceholderPng}
            />
          </div>
          <div>&nbsp;</div>
          <div
            className="mt-4"
            style={{
              padding: "2em",
              border: `1px solid gray`
            }}
          >
            <P>
              We have sent instructions to reset your password to the email
              address you entered.
            </P>
            <P>You should be receiving it shortly.</P>
            <Link to={"/"} style={{ textDecoration: "none", color: "inherit" }}>
              <Button className="mx-auto">Back to login page</Button>
            </Link>
          </div>
        </div>
      </Container>
    );
  }
}

export default PasswordResetInstructionsView;
