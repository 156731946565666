import React, { Component } from "react";
import glamorous from "glamorous";
import { withRouter } from "react-router";
import queryString from "query-string";

import Progress from "antd/lib/progress";
import BackTop from "antd/lib/back-top";
import Pagination from "antd/lib/pagination";
import { withAuth } from "../components/utils/index.js";

import H1 from "../components/web/typography/H1.js";
import P from "../components/web/typography/P.js";
import Button from "../components/web/buttons/Button.js";
import ImageFixedProportions from "../components/web/images/ImageFixedProportions.js";
import ModalLoading from "../components/web/modals/ModalLoading.js";
import ErrorMessageGeneral from "../components/web/misc/ErrorMessageGeneral.js";

import ImageProductPlaceholderPng from "../static/ImageProductPlaceholder.png";

const Container = glamorous.div(props => ({
  display: "flex",
  position: "relative",
  width: "100%"
}));
Container.displayName = "views.OrdersView.Container";

const OrderDiv = glamorous.div(props => ({
  backgroundColor: "white",
  padding: "1em",
  width: "100%",
  marginBottom: "1em"
}));
OrderDiv.displayName = "views.OrdersView.OrderDiv";

const ImageDiv = glamorous.div(props => ({
  minWidth: "50px",
  marginRight: "0.3em",
  border: `1px solid ${props.theme.colorGrayLight}`,
  marginBottom: "0.3em",
  "@media(min-width: 767px)": {
    minWidth: "90px",
    marginRight: "1em",
    marginBottom: "1em"
  }
}));

ImageDiv.displayName = "views.OrdersView.ImageDiv";

const OrderDivTop = glamorous.div(props => ({
  "@media(min-width: 767px)": {
    display: "flex"
  }
}));
OrderDivTop.displayName = "views.OrdersView.OrderDivTop";

const OrderDivTopLeft = glamorous.div(props => ({
  flex: 1,
  "@media(max-width: 767px)": {
    marginBottom: "1em"
  }
}));
OrderDivTopLeft.displayName = "views.OrdersView.OrderDivTopLeft";

const ProgressDiv = glamorous.div(props => ({
  "@media(max-width: 767px)": {
    width: "80%"
  },
  "@media(min-width: 767px)": {
    width: "50%"
  }
}));
ProgressDiv.displayName = "views.OrdersView.ProgressDiv";

const OrderDivBottom = glamorous.div(props => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center"
}));
OrderDivBottom.displayName = "views.OrdersView.OrderDivBottom";

class OrdersView extends Component {
  constructor(props) {
    super(props);
    let jwtToken = localStorage.getItem("id_token");
    this.state = {
      jwtToken: jwtToken,
      data: [],
      isLoading: false,
      error: null
    };
  }

  fetchOrders(api) {
    this.setState({ isLoading: true });
    this.props.auth
      .fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}${api}`)
      .then(data => {
        this.setState({
          data: data.results,
          numOfOrders: data.count,
          isLoading: false
        });
      })
      .catch(error => {
        this.setState({ error: true, isLoading: false });
      });
    window.scrollTo(0, 0);
  }

  componentDidMount() {
    let page = queryString.parse(this.props.location.search).page || 1;
    this.fetchOrders(
      `/order_platform/get_backorderitems_by_order/?page=${page}`
    );
  }

  componentDidUpdate(prevProps) {
    let prevPage = queryString.parse(prevProps.location.search).page;
    let currPage = queryString.parse(this.props.location.search).page;
    if (prevPage !== currPage) {
      this.fetchOrders(
        `/order_platform/get_backorderitems_by_order/?page=${currPage}`
      );
    }
  }

  handleViewOrderClick(orderId, orderDate, backorderItems) {
    this.props.history.push({
      pathname: `/profile/orders/${orderId}`,
      state: {
        orderId: orderId,
        orderDate: orderDate,
        backorderItems: backorderItems
      }
    });
  }

  getPercentFulfilled(elem) {
    let result;
    let totalQtyOrdered = 0;
    let totalQtyFulfilled = 0;

    let backorderItems = elem.backorder_items;
    for (var i = 0; i < backorderItems.length; i++) {
      totalQtyOrdered += backorderItems[i].quantity;
    }
    for (var j = 0; j < backorderItems.length; j++) {
      totalQtyFulfilled += backorderItems[j].qty_fulfilled;
    }

    result = (totalQtyFulfilled / totalQtyOrdered) * 100;
    return Math.round(result);
  }

  renderNoOrders() {
    return (
      <div>
        <P>You haven't placed any orders yet.</P>
      </div>
    );
  }

  getSrc(item) {
    // not sure why base url needs to be appended before image src
    // in ProductGridView, full url for image is returned from backend
    // TODO: check with Shyde

    let result = process.env.REACT_APP_BACKEND_BASE_URL;

    result +=
      !!item.product &&
      !!item.product.photo_set &&
      !!item.product.photo_set[0] &&
      item.product.photo_set[0].image;

    return result;
  }

  renderNonEmptyOrders() {
    const { data, numOfOrders } = this.state;
    let currentPage = queryString.parse(this.props.location.search).page || 1;
    return (
      <React.Fragment>
        {data.map(elem => (
          <OrderDiv key={elem.order.id}>
            <OrderDivTop>
              <OrderDivTopLeft>
                <table>
                  <tbody>
                    <tr style={{ verticalAlign: "text-top" }}>
                      <td>
                        <P className="mb-1" style={{ paddingRight: "0.5em" }}>
                          Order No:
                        </P>
                      </td>
                      <td>
                        <P className="mb-1">{elem.order.id}</P>
                      </td>
                    </tr>
                    <tr style={{ verticalAlign: "text-top" }}>
                      <td>
                        <P className="mb-1" style={{ paddingRight: "0.5em" }}>
                          Order Date:
                        </P>
                      </td>
                      <td>
                        <P className="mb-1" style={{ flex: 1 }}>
                          {elem.order.order_submission_date}
                        </P>
                      </td>
                    </tr>
                    <tr style={{ verticalAlign: "text-top" }}>
                      <td>
                        <P className="mb-1" style={{ paddingRight: "1em" }}>
                          Order Value:
                        </P>
                      </td>
                      <td>
                        <P className="mb-1" style={{ flex: 1 }}>
                          ${elem.total_order_value.toFixed(2)}
                        </P>
                      </td>
                    </tr>
                    {elem.total_order_value - elem.total_order_fulfilled_value >
                      0 && (
                      <tr style={{ verticalAlign: "text-top" }}>
                        <td>
                          <P className="mb-1" style={{ paddingRight: "1em" }}>
                            Backorder Value:
                          </P>
                        </td>
                        <td>
                          <P className="mb-1" style={{ flex: 1 }}>
                            ${(
                              elem.total_order_value -
                              elem.total_order_fulfilled_value
                            ).toFixed(2)}
                          </P>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
                <ProgressDiv>
                  <Progress
                    percent={this.getPercentFulfilled(elem)}
                    size="small"
                    format={percent => `${percent}% fulfilled`}
                  />
                </ProgressDiv>
              </OrderDivTopLeft>
              <Button
                buttonType="white"
                style={{ height: "35px" }}
                onClick={() =>
                  this.handleViewOrderClick(
                    elem.order.id,
                    elem.order.order_submission_date,
                    elem.backorder_items
                  )
                }
              >
                <P small className="mb-0">
                  View Order Details
                </P>
              </Button>
            </OrderDivTop>
            <hr />
            <OrderDivBottom>
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap"
                }}
              >
                {elem.backorder_items.map(item => (
                  <ImageDiv key={item.id}>
                    <ImageFixedProportions
                      src={this.getSrc(item)}
                      placeholderPng={ImageProductPlaceholderPng}
                    />
                  </ImageDiv>
                ))}
              </div>
            </OrderDivBottom>
          </OrderDiv>
        ))}
        <div>&nbsp;</div>
        <div
          className="row"
          style={{ paddingBottom: "50px", textAlign: "center" }}
        >
          <Pagination
            style={{ width: "100%" }}
            total={numOfOrders}
            pageSize={20}
            onChange={e => this.handlePaginationChange(e, 20)}
            current={parseInt(currentPage, 10)}
          />
        </div>
      </React.Fragment>
    );
  }

  handlePaginationChange(page, pageSize) {
    let currPathname = this.props.location.pathname;
    this.props.history.push(`${currPathname}?page=${page}`);
  }

  render() {
    const { data, isLoading, error } = this.state;

    if (isLoading) {
      return <ModalLoading isVisible />;
    }

    if (error) {
      return <ErrorMessageGeneral />;
    }

    return (
      <React.Fragment>
        <div>
          <H1 medium className="text-left mt-2 mb-4">
            MY ORDERS
          </H1>
          {!!data && data.length === 0 && this.renderNoOrders()}
          {!!data && data.length > 0 && this.renderNonEmptyOrders()}
        </div>
        <BackTop />
      </React.Fragment>
    );
  }
}

export default withAuth(withRouter(OrdersView));
