import glamorous from "glamorous"

const InputIconAddOn = glamorous.div(
  props => ({
    alignItems: "center",
    backgroundColor: props.bgPrimary ? props.theme.colorPrimary : "none",
    border: "none",
    display: "flex",
    flex: "0 0 auto",
    justifyContent: "center",
    paddingLeft: "6px",
    paddingRight: "6px",
    width: props.theme.heightInputMedium,
    height: "auto"
  }),
  props => {
    if (props.displayAbsolute) {
      return {
        position: "absolute",
        height: `${props.theme.heightInputNum - 2}px`,
        width: `${props.theme.heightInputNum - 2}px`,
        top: 1,
        left: 1
      }
    }
    return null
  }
)
InputIconAddOn.displayName = "web.inputs.InputIconAddOn"

export default InputIconAddOn
