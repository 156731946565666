import React from "react";
import glamorous from "glamorous";

import IconLoadingSpinner from "../icons/IconLoadingSpinner.js";

const DarkBackground = glamorous.div(props => ({
  // display: props.isVisible ? "block" : "none",
  position: "fixed",
  top: 0,
  right: 0,
  left: 0,
  bottom: 0,
  width: "100%",
  height: props.isVisible ? "100%" : 0,
  overflow: props.isVisible ? "hidden" : "auto",
  backgroundColor: "black",
  opacity: props.isVisible ? 0.05 : 0,
  zIndex: props.isVisible ? props.theme.zIndexDarkBackground : -1,
  transition: "opacity 1s ease-in-out"
}));
DarkBackground.displayName = "core.modals.ModalLoading.DarkBackground";

class ModalLoading extends React.Component {
  static displayName = "core.modals.ModalLoading";

  render() {
    const { isVisible } = this.props;
    if (!isVisible) {
      return (
        <div
          style={{ display: "none" }}
          data-test="invisible-ModalLoading-notLoading"
        />
      );
    }
    return (
      <div
        data-test="invisible-ModalLoading-isLoading"
        style={{ position: "relative", width: "100%", height: "100%" }}
      >
        <DarkBackground isVisible={isVisible} />
        <IconLoadingSpinner floating big />
      </div>
    );
  }
}

export default ModalLoading;
