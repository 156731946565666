import React, { Component } from "react"
import PropTypes from "prop-types"

import ModalFadeInSuccess from "./ModalFadeInSuccess.js"
import ModalFadeInFormErrors from "./ModalFadeInFormErrors.js"

class ModalFadeInForForms extends Component {
  static displayName = "core.modals.ModalFadeInForForms"

  static propTypes = {
    errorMsg: PropTypes.string,
    showSuccess: PropTypes.bool,
    successMsg: PropTypes.string
  }

  static defaultProps = {
    errorMsg: "default",
    showSuccess: false,
    successMsg: undefined
  }

  render() {
    const {
      errorMsg,
      formErrors,
      showSuccess,
      successMsg,
      dataTest
    } = this.props
    let errorMessage = errorMsg
    if (errorMsg === "default") {
      errorMessage = "Please correct the errors above"
    }

    return (
      <div data-test={dataTest}>
        {successMsg &&
          <ModalFadeInSuccess show={showSuccess}>
            {successMsg}
          </ModalFadeInSuccess>}
        <ModalFadeInFormErrors show={Object.keys(formErrors).length > 0}>
          {errorMessage}
        </ModalFadeInFormErrors>
      </div>
    )
  }
}

export default ModalFadeInForForms
