import React, { Component } from "react";
import AuthService from "../web/auth/AuthService.js";

export function withAuth(AuthComponent) {
  const Auth = new AuthService(process.env.REACT_APP_BACKEND_BASE_URL);
  return class AuthWrapped extends Component {
    constructor() {
      super();
      this.state = {
        user: null
      };
    }

    componentWillMount() {
      if (!Auth.loggedIn()) {
        //TODO: need to change this url to production url
        if (this.props.location.pathname !== "/") {
          window.location.href = process.env.REACT_APP_FRONTEND_URL;
        }
      } else {
        try {
          const profile = Auth.getProfile();
          this.setState({
            user: profile
          });
        } catch (err) {
          Auth.logout();
        }
      }
    }

    render() {
      if (this.state.user) {
        return (
          <AuthComponent
            history={this.props.history}
            user={this.state.user}
            moreProps={this.props}
            auth={Auth}
          />
        );
      } else {
        return null;
      }
    }
  };
}
