import React, { Component } from "react";
import serialize from "form-serialize";
import Cookies from "js-cookie";

import { withAuth } from "../../utils/index.js";

import ModalFadeInForForms from "../modals/ModalFadeInForForms.js";
import InputText from "../inputs/InputText.js";
import Button from "../buttons/Button.js";
import ErrorMessageGeneral from "../misc/ErrorMessageGeneral.js";

class FormProfileMyDetails extends Component {
  constructor(props) {
    super(props);
    let jwtToken = localStorage.getItem("id_token");

    this.state = {
      jwtToken: jwtToken,
      formErrors: "",
      isSuccesfullyChanged: false,
      formKey: 0,
      firstName: "",
      lastName: "",
      email: ""
    };
  }

  componentDidMount() {
    this.setState({ isLoading: true });

    this.props.auth
      .fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/auth/user_info/`)
      .then(data =>
        this.setState({
          firstName: data.first_name,
          lastName: data.last_name,
          email: data.email
        })
      )
      .catch(error => {
        this.setState({ error: true, isLoading: false });
      });
  }

  handleSubmit(e) {
    e.preventDefault();
    const serializedFormValues = serialize(this.form, { hash: true });

    let csrfToken = Cookies.get("csrftoken");

    const firstName = serializedFormValues.firstName;
    const lastName = serializedFormValues.lastName;
    const email = serializedFormValues.email;

    if (
      firstName === undefined &&
      lastName === undefined &&
      email === undefined
    ) {
      this.setState({
        formErrors: {
          firstNameFormError: "This field is mandatory",
          lastNameFormError: "This field is mandatory",
          emailFormError: "This field is mandatory"
        },
        isSuccesfullyChanged: false
      });
    } else if (
      firstName === undefined &&
      lastName !== undefined &&
      email !== undefined
    ) {
      this.setState({
        formErrors: {
          firstNameFormError: "This field is mandatory"
        },
        isSuccesfullyChanged: false
      });
    } else if (
      firstName !== undefined &&
      lastName === undefined &&
      email !== undefined
    ) {
      this.setState({
        formErrors: {
          lastNameFormError: "This field is mandatory"
        },
        isSuccesfullyChanged: false
      });
    } else if (
      firstName !== undefined &&
      lastName !== undefined &&
      email === undefined
    ) {
      this.setState({
        formErrors: {
          emailFormError: "This field is mandatory"
        },
        isSuccesfullyChanged: false
      });
    } else if (
      firstName === undefined &&
      lastName === undefined &&
      email !== undefined
    ) {
      this.setState({
        formErrors: {
          firstNameFormError: "This field is mandatory",
          lastNameFormError: "This field is mandatory"
        },
        isSuccesfullyChanged: false
      });
    } else if (
      firstName === undefined &&
      lastName !== undefined &&
      email === undefined
    ) {
      this.setState({
        formErrors: {
          firstNameFormError: "This field is mandatory",
          emailFormError: "This field is mandatory"
        },
        isSuccesfullyChanged: false
      });
    } else if (
      firstName !== undefined &&
      lastName === undefined &&
      email === undefined
    ) {
      this.setState({
        formErrors: {
          lastNameFormError: "This field is mandatory",
          emailFormError: "This field is mandatory"
        },
        isSuccesfullyChanged: false
      });
    } else {
      this.setState({ isLoading: true });
      this.props.auth
        .fetch(
          `${process.env.REACT_APP_BACKEND_BASE_URL}/auth/user_info/`,
          {
            method: "PUT",
            body: `first_name=${firstName}&last_name=${lastName}&email=${email}&csrfmiddlewaretoken=${csrfToken}`,
            credentials: "include"
          },
          {
            "Content-Type": "application/x-www-form-urlencoded",
            "x-csrftoken": csrfToken
          }
        )
        .then(data =>
          this.setState({
            isLoading: false,
            formErrors: "",
            isSuccesfullyChanged: true
          })
        )
        .catch(error => {
          this.setState({ error: true, isLoading: false });
        });
    }
  }

  render() {
    const {
      formKey,
      firstName,
      lastName,
      email,
      formErrors,
      isSuccesfullyChanged,
      error
    } = this.state;
    if (error) {
      return <ErrorMessageGeneral />;
    }
    return (
      <div>
        <form
          key={formKey}
          method="post"
          action="."
          onSubmit={e => this.handleSubmit(e)}
          ref={ref => (this.form = ref)}
        >
          <InputText
            labelName="First Name"
            inputName="firstName"
            type="text"
            initialValue={firstName}
            error={formErrors.firstNameFormError}
          />
          <InputText
            labelName="Last Name"
            type="text"
            inputName="lastName"
            initialValue={lastName}
            error={formErrors.lastNameFormError}
          />
          <InputText
            labelName="Email"
            type="text"
            inputName="email"
            initialValue={email}
            error={formErrors.emailFormError}
          />
          <Button isFullWidth className="mt-3">
            Update My Details
          </Button>
        </form>
        <div className="mt-2 mb-2">
          <ModalFadeInForForms
            formErrors={formErrors}
            errorMsg={formErrors.formError}
            showSuccess={isSuccesfullyChanged}
            successMsg={"Your details have been successfully updated"}
          />
        </div>
      </div>
    );
  }
}

export default withAuth(FormProfileMyDetails);
