import React, { Component } from "react";
import PropTypes from "prop-types";
import glamorous from "glamorous";

import P from "../typography/P.js";

const Container = glamorous.div(props => {
  const BadgeColor = {
    FUL: props.theme.colorBadgeFulfilled,
    PARTFUL: props.theme.colorBadgePartiallyFulfilled,
    PGF: props.theme.colorBadgePendingFulfilment,
    DISCON: props.theme.colorBadgeDiscontinued,
    DEL: props.theme.colorDanger
  };
  return {
    backgroundColor: BadgeColor[props.badgeType],
    position: props.isDiscon ? "relative" : "absolute",
    opacity: props.isDiscon ? "0.4" : 1,
    width: props.isDiscon ? "max-content" : "auto",
    display: props.isDiscon ? "flex" : "auto",
    zIndex: 1,
    padding: "2px 5px",
    top: "8px",
    left: "8px",
    borderRadius: "5px"
  };
});
Container.displayName = "web.Orders.OrderItemBadge.Container";

class OrderItemBadge extends Component {
  static propTypes = {
    status: PropTypes.string.isRequired,
    badgeType: PropTypes.string
  };

  getStatusFullText(status) {
    let result;
    switch (status) {
      case "FUL":
        result = "Fulfilled";
        break;
      case "PARTFUL":
        result = "Partially Fulfilled";
        break;
      case "PGF":
        result = "Pending Fulfilment";
        break;
      case "DISCON":
        result = "Discontinued";
        break;
      case "DEL":
        result = "Deleted";
        break;
      default:
        result = "Pending Fulfilment";
    }
    return result;
  }

  render() {
    const { status, isDiscon } = this.props;
    return (
      <Container badgeType={status} isDiscon={isDiscon}>
        <P
          white
          fineprint
          className="mb-0"
          style={isDiscon ? { display: "inline-block" } : { display: "block" }}
        >
          {this.getStatusFullText(status)}
        </P>
      </Container>
    );
  }
}

export default OrderItemBadge;
