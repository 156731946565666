import React, { Component } from "react";
import PropTypes from "prop-types";
import glamorous from "glamorous";

import P from "../typography/P.js";

const Container = glamorous.div(props => {
  const BadgeColor = {
    DISCON_RMG: props.theme.colorGrayMedium
  };
  return {
    backgroundColor: BadgeColor[props.badgeType],
    position: "absolute",
    opacity: 1,
    width: "auto",
    display: "block",
    zIndex: 1,
    padding: "2px 5px",
    top: "8px",
    left: "8px",
    borderRadius: "5px"
  };
});
Container.displayName = "web.Orders.OrderItemBadge.Container";

class ProductBadge extends Component {
  static propTypes = {
    status: PropTypes.string.isRequired,
    badgeType: PropTypes.string
  };

  getStatusFullText(status) {
    let result;
    switch (status) {
      case "DISCON_RMG":
        result = "Catch Me While You Can";
        break;
      default:
        result = "In Stock";
    }
    return result;
  }

  render() {
    const { status } = this.props;
    return (
      <Container badgeType={status}>
        <P white fineprint className="mb-0">
          {this.getStatusFullText(status)}
        </P>
      </Container>
    );
  }
}

export default ProductBadge;
