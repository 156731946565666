import React, { Component } from "react";

import InputSelect from "../inputs/InputSelect.js";
import { valuesForProductQtyInputSelect } from "../../../utils/index.js";

class CartRowQuantity extends Component {
  static displayName = "web.cart.CartRowQuantity";

  constructor(props) {
    super(props);
    this.state = {
      value: this.props.quantity,
      showOverlay: false
    };
  }

  componentDidMount() {
    this.setState({
      value: this.props.quantity,
      showOverlay: false
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.isCancelClicked !== this.props.isCancelClicked) {
      this.setState({
        value: this.props.quantity
      });
    }
    if (prevProps.quantity !== this.props.quantity) {
      this.setState({
        value: this.props.quantity
      });
    }
  }

  handleChange(e) {
    this.setState({
      value: e.target.value,
      showOverlay: true
    });
    if (this.props.onChange) {
      this.props.onChange(e.target.value);
    }
  }

  render() {
    const { value } = this.state;
    const { packQty } = this.props;

    return (
      <div style={{ width: "30%", display: "flex" }}>
        <InputSelect
          label={"Quantity: "}
          data={valuesForProductQtyInputSelect(packQty, value)}
          value={this.state.value}
          onChange={e => this.handleChange(e)}
        />
      </div>
    );
  }
}

export default CartRowQuantity;
