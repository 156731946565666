import React, { Component } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import serialize from "form-serialize";
import glamorous from "glamorous";
import Menu from "antd/lib/menu";

import { Link } from "react-router-dom";

import "antd/dist/antd.css";

import { withAuth } from "../../utils/index.js";

import CartBadge from "../cart/CartBadge.js";
import P from "../typography/P.js";
import ImageResponsive from "../images/ImageResponsive.js";
import ImageFixedProportions from "../images/ImageFixedProportions.js";
import InputText from "../inputs/InputText.js";
import IconCartSvg from "../../../static/IconCart.svg";
import IconProfileSvg from "../../../static/IconProfile.svg";
import IconSearchPrimarySvg from "../../../static/IconSearchPrimary.svg";

import ImageJellycatLogoPng from "../../../static/ImageJellycatLogo.png";
import ImageJellycatLogoPlaceholderPng from "../../../static/ImageJellycatLogoPlaceholder.png";

import theme from "../../theme.js";

const SubMenu = Menu.SubMenu;

const NavigationPrimary = glamorous.div(props => ({
  fontFamily: props.theme.fontPrimary,
  fontSize: props.theme.fontSizeP,
  display: "flex",
  alignItems: "center",
  padding: "1em",
  justifyContent: "space-between",
  zIndex: props.zIndexNavigationMain,
  width: "100%",
  "@media(min-width: 767px)": {
    padding: "1em 2.5em 0.5em 2.5em"
  }
}));
NavigationPrimary.displayName =
  "web.navigation.NavigationMain.NavigationPrimary";

const NavigationSecondary = glamorous.div(props => ({
  fontFamily: props.theme.fontPrimary,
  position: "relative",
  width: "100%",
  zIndex: props.zIndexNavigationMain
}));
NavigationSecondary.displayName =
  "web.navigation.NavigationMain.NavigationSecondary";

const SearchDiv = glamorous.div(props => ({
  flex: 3,
  display: "flex",
  position: "relative",
  alignItems: "center"
}));
SearchDiv.displayName = "web.navigation.NavigationMain.SearchDiv";

const SearchIconDiv = glamorous.div(props => ({
  position: "absolute",
  right: 0,
  flex: 1,
  zIndex: props.theme.zIndexNavigationMainSearch,
  width: "24px",
  height: "24px",
  marginRight: "0.8em",
  cursor: "pointer"
}));
SearchIconDiv.displayName = "web.navigation.NavigationMain.SearchIconDiv";

const IconProfileDiv = glamorous.div(props => ({
  cursor: "pointer",
  display: "flex",
  width: "25px",
  "@media(min-width: 767px)": {
    width: "30px"
  }
}));
IconProfileDiv.displayName = "web.navigation.NavigationMain.IconProfileDiv";

const IconCartDiv = glamorous.div(props => ({
  cursor: "pointer",
  display: "flex",
  width: "25px",
  "@media(min-width: 767px)": {
    width: "30px"
  }
}));
IconCartDiv.displayName = "web.navigation.NavigationMain.IconCartDiv";

class NavigationMain extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      searchValue: "",
      jwtToken: undefined,
      showNav: true,
      isLoading: false,
      error: null,
      dataCartBadge: 0,
      isDesktop: false
    };
    this.updatePredicate = this.updatePredicate.bind(this);
  }

  componentWillMount() {
    window.removeEventListener("resize", this.updatePredicate);
    let currPathname = this.props.location.pathname;
    if (
      currPathname === "/password/reset/" ||
      currPathname === "/password/reset/instructions" ||
      currPathname.indexOf("/password/reset/key/") > -1
    ) {
      this.setState({ showNav: false });
    }
  }

  componentDidMount() {
    this.setState({ isLoading: true });
    this.updatePredicate();
    window.addEventListener("resize", this.updatePredicate);

    this.props.auth
      .fetch(
        `${
          process.env.REACT_APP_BACKEND_BASE_URL
        }/order_platform/get_all_categories/`
      )
      .then(data => this.setState({ data: data, isLoading: false }))
      .catch(error => {
        this.setState({ error: true, isLoading: false });
      });

    this.props.auth
      .fetch(
        `${
          process.env.REACT_APP_BACKEND_BASE_URL
        }/order_platform/get_cart_items/`
      )
      .then(data =>
        this.setState({
          dataCartBadge: data.results,
          isLoading: false
        })
      )
      .catch(error => {
        this.setState({ error: true, isLoading: false });
      });
  }

  updatePredicate() {
    this.setState({ isDesktop: window.innerWidth > 767 });
  }

  handleMenuItemClick(e) {
    if (e.key === "all") {
      this.props.history.push("/products/");
    } else {
      this.props.history.push(`/products/${e.key}/`);
    }
    this.setState({ current: e.key });
  }

  submitForm() {
    const serializedFormValues = serialize(this.form, { hash: true });
    this.setState({ searchValue: serializedFormValues.search }, () => {
      if (this.state.searchValue !== undefined) {
        this.props.history.push(
          `/products/search/?search=${this.state.searchValue}`
        );
      } else {
        this.props.history.push("/products/");
      }
    });
  }

  handleSearchSubmit(e) {
    e.preventDefault();
    this.submitForm();
  }

  render() {
    const { showNav, dataCartBadge = [], isDesktop } = this.state;
    let categories = this.state.data || [];

    if (showNav === false) {
      return null;
    } else {
      return (
        <div
          style={{
            backgroundColor: "white !important"
          }}
        >
          <NavigationPrimary>
            <div className="d-block d-md-block">
              <Link to={"/products/"} style={{ textDecoration: "none" }}>
                <ImageFixedProportions
                  src={ImageJellycatLogoPng}
                  placeholderPng={ImageJellycatLogoPlaceholderPng}
                  style={{ width: "80px" }}
                />
              </Link>
            </div>
            <SearchDiv>
              <form
                method="post"
                action="."
                style={{ width: "90%", margin: "0 auto" }}
                onSubmit={e => this.handleSearchSubmit(e)}
                ref={ref => (this.form = ref)}
              >
                <InputText
                  placeholder={
                    isDesktop
                      ? "Search by product name or product code"
                      : "Search"
                  }
                  type="text"
                  customStyle={{ paddingBottom: 0 }}
                  iconRightSrc={IconSearchPrimarySvg}
                  inputName="search"
                  handleOnClick={value => {
                    this.handleSearchSubmit(value);
                  }}
                />
              </form>
            </SearchDiv>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center"
              }}
            >
              <Link
                style={{ textDecoration: "none", color: "inherit" }}
                to={"/profile/"}
              >
                <IconProfileDiv>
                  <ImageResponsive src={IconProfileSvg} isIcon />
                </IconProfileDiv>
              </Link>
              <div className="mr-1 ml-1">&nbsp;</div>
              <CartBadge numOfItems={dataCartBadge.length} />
              <IconCartDiv>
                <Link to={"/cart/"} style={{ textDecoration: "none" }}>
                  <ImageResponsive src={IconCartSvg} isIcon />
                </Link>
              </IconCartDiv>
            </div>
          </NavigationPrimary>
          <NavigationSecondary>
            <Menu
              mode={isDesktop ? "horizontal" : "vertical"}
              style={{
                backgroundColor: theme.colorPrimary
              }}
              selectedKeys={[this.state.current]}
              onClick={e => this.handleMenuItemClick(e)}
            >
              <Menu.Item
                key={"all"}
                style={
                  isDesktop
                    ? { width: "33.33%" }
                    : { width: "100%", marginTop: "0" }
                }
              >
                <P className="mb-0" uppercase fontWeightHeavy small>
                  All Products
                </P>
              </Menu.Item>
              <SubMenu
                title={
                  <P small className="mb-0" uppercase fontWeightHeavy>
                    By Catalogue
                  </P>
                }
                style={isDesktop ? { width: "33.33%" } : { width: "100%" }}
              >
                {!!categories &&
                  categories.length > 0 &&
                  categories.map((item, index) => (
                    <Menu.Item key={item.category}>{item.category}</Menu.Item>
                  ))}
              </SubMenu>
              <Menu.Item
                key={"Catch Me While You Can"}
                style={
                  isDesktop
                    ? { width: "33.33%" }
                    : { width: "100%", marginBottom: "0" }
                }
              >
                <P small className="mb-0" uppercase fontWeightHeavy>
                  Catch Me While You Can
                </P>
              </Menu.Item>
            </Menu>
          </NavigationSecondary>
        </div>
      );
    }
  }
}

const mapStateToProps = state => {
  return { showCartPopover: state.cart.showCartPopover };
};

export default withAuth(withRouter(connect(mapStateToProps)(NavigationMain)));
