import React, { Component } from "react";
import { connect } from "react-redux";
import glamorous from "glamorous";

import AuthService from "../auth/AuthService.js";

import P from "../typography/P.js";

const Auth = new AuthService(process.env.REACT_APP_BACKEND_BASE_URL);

const BadgeContainer = glamorous.div(props => ({
  width: "25px",
  height: "25px",
  backgroundColor: props.theme.colorSecondary,
  borderRadius: "15px",
  position: "absolute",
  top: "12px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  right: "20px"
}));
BadgeContainer.displayName = "cart.CartBadge.BadgeContainer";

class CartBadge extends Component {
  static displayName = "cart.CartBadge";

  constructor(props) {
    super(props);
    this.state = {
      data: [],
      jwtToken: undefined,
      isLoading: false,
      error: null
    };
  }

  refreshData() {
    return Auth.fetch(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/order_platform/get_cart_items/`
    )
      .then(data => {
        this.setState({
          data: data,
          isLoading: false
        });
      })
      .catch(error => {
        this.setState({ error: true, isLoading: false });
      });
  }
  componentDidMount() {
    this.refreshData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.updateCartBadge !== this.props.updateCartBadge) {
      this.refreshData();
    }
  }

  render() {
    const { data = [], error } = this.state;
    if (error || data.length === 0) {
      return null;
    }
    return (
      <BadgeContainer>
        <P white smaller className="mb-0">
          {data.length}
        </P>
      </BadgeContainer>
    );
  }
}

const mapStateToProps = state => {
  return {
    updateCartBadge: state.cart.updateCartBadge
  };
};

export default connect(mapStateToProps)(CartBadge);
