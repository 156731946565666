import glamorous from 'glamorous'

const H1 = glamorous.h1(
  props => ({
    color: props.theme.colorBlack,
    fontFamily: props.fontPrimary,
    fontSize: props.theme.fontSizeH1, // 17pt,
    letterSpacing: props.theme.letterSpacingNormal,
  }),
  props =>
    props.large && {
      fontSize: props.theme.fontSizeH1Large, //  26pt
    },
  props =>
    props.medium && {
      fontSize: props.theme.fontSizeH1Medium, // 15pt
    },
  props =>
    props.mediumSmall && {
      fontSize: props.theme.fontSizeH1MediumSmall, //14pt
    },
  props =>
    props.small && {
      fontSize: props.theme.fontSizeH1Small, // 12 pt
    },
  props =>
    props.gray && {
      color: props.theme.colorGray,
    },
  props =>
    props.primary && {
      color: props.theme.colorPrimary,
    },
  props =>
    props.narrow && {
      letterSpacing: props.theme.letterSpacingNarrow,
    },
  props =>
    props.wide && {
      letterSpacing: props.theme.letterSpacingWide,
    },
  props =>
    props.bold && {
      fontWeight: 600,
    },
  props =>
    props.ellipsis && {
      maxWidth: '100%',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
  props =>
    props.white && {
      color: 'white',
    }
)

H1.displayName = 'web.typography.H1'

export default H1
