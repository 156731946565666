import React, { Component } from "react";
import glamorous from "glamorous";
import { withRouter } from "react-router";

import Modal from "antd/lib/modal";

import Button from "antd/lib/button";
import Cookies from "js-cookie";
import { connect } from "react-redux";

import AuthService from "../auth/AuthService.js";
import { UPDATE_CART_BADGE } from "../../../redux/actionTypes.js";
import ImageFixedProportions from "../images/ImageFixedProportions.js";
import P from "../typography/P.js";
import InputSelect from "../inputs/InputSelect.js";
import { valuesForProductQtyInputSelect } from "../../../utils/index.js";
import ErrorMessageGeneral from "../misc/ErrorMessageGeneral.js";
import ProductBadge from "./ProductBadge.js";

import ImageProductPlaceholderPng from "../../../static/ImageProductPlaceholder.png";

const Container = glamorous.div(props => ({
  height: "100%",
  cursor: "pointer",
  padding: "1em",
  border: "1px solid white",
  ":hover": {
    border: `1px solid ${props.theme.colorGrayLight}`,
    borderRadius: "5px",
    boxShadow: `0px 0px 3px ${props.theme.colorGrayLight}`,
    transition: "box-shadow 0.2s ease-in-out"
  }
}));
Container.displayName = "web.products.Product.Container";

const CategoryTagDiv = glamorous.div(props => ({
  padding: "4px",
  borderRadius: "2px",
  display: "inline-block"
}));
CategoryTagDiv.displayName = "web.products.Product.CategoryTagDiv";

const ProductNameDiv = glamorous.div(props => ({
  "@media(max-width: 767px)": {
    minHeight: "auto"
  },
  "@media(min-width: 767px)": {
    minHeight: "48px"
  }
}));
ProductNameDiv.displayName = "web.products.Product.ProductNameDiv";

const DescriptionDiv = glamorous.div(props => ({
  "@media(max-width: 767px)": {},
  "@media(min-width: 767px)": {
    display: "flex",
    justifyContent: "space-between"
  }
}));
DescriptionDiv.displayName = "web.products.Product.DescriptionDiv";

const Auth = new AuthService(process.env.REACT_APP_BACKEND_BASE_URL);

class Product extends Component {
  constructor(props) {
    super(props);
    let jwtToken = localStorage.getItem("id_token");

    this.state = {
      jwtToken: jwtToken,
      modalIsVisible: false,
      productId: "",
      quantity: props.packQty,
      confirmLoading: false,
      isLoading: false,
      error: null,
      addedToCart: false,
      inputSelectChanged: false,
      backordersData: [],
      backordersDataError: null,
      modalIncreaseBackordersIsVisible: false,
      backorderItem: {}
    };
  }

  showModal() {
    Auth.fetch(
      `${
        process.env.REACT_APP_BACKEND_BASE_URL
      }/order_platform/check_item_in_backorder/?product_id=${this.props.id}`
    )
      .then(data => {
        if (data.is_in_backorder === true) {
          this.setState({
            modalIncreaseBackordersIsVisible: true,
            modalIsVisible: false,
            backorderItem: data.cart_item
          });
        } else {
          this.setState({
            modalIncreaseBackordersIsVisible: false,
            modalIsVisible: true
          });
        }
      })
      .catch(error => {
        this.setState({ backordersDataError: true });
      });
  }

  handleCloseModal() {
    this.setState({
      modalIsVisible: false,
      addedToCart: !this.state.addedToCart
    });
  }

  handleCloseBackordersModal() {
    this.setState({
      modalIncreaseBackordersIsVisible: false
    });
  }

  handleInputSelectChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
      inputSelectChanged: true
    });
  }

  handleAddToCart(e) {
    e.preventDefault();
    // get our form data out of state
    const { inputSelectChanged } = this.state;
    const { id, productName, code } = this.props;
    let qtyToBeSubmitted = inputSelectChanged
      ? this.state.quantity
      : this.props.packQty;

    let csrfToken = Cookies.get("csrftoken");

    this.setState({ isLoading: true, confirmLoading: true });
    Auth.fetch(
      `${
        process.env.REACT_APP_BACKEND_BASE_URL
      }/order_platform/get_cart_items/`,
      {
        method: "POST",
        body: `product=${id}&quantity=${qtyToBeSubmitted}&csrfmiddlewaretoken=${csrfToken}`,
        credentials: "include"
      },
      {
        "Content-Type": "application/x-www-form-urlencoded"
      }
    )
      .then(data =>
        this.setState({
          isLoading: false,
          confirmLoading: false,
          modalIsVisible: false,
          inputSelectChanged: false
        })
      )
      .then(() => {
        Modal.success({
          title: (
            <div>
              <P>Item Succesfully Added</P>
            </div>
          ),
          content: (
            <div>
              <P>{`${productName} (${code}) has been added to your cart`}</P>
            </div>
          )
        });
        this.setState({
          addedToCart: !this.state.addedToCart
        });
        this.props.dispatch({
          type: UPDATE_CART_BADGE,
          payload: {
            update: !this.props.updateCartBadge
          }
        });
      })
      .catch(error => {
        this.setState({
          isLoading: false,
          confirmLoading: false,
          modalIsVisible: false
        });
        Modal.error({
          title: "Unable to Add Item",
          content: (
            <P>
              Sorry, something seems to have gone wrong :( <br />
              <br /> Please contact{" "}
              <a href="mailto:orders@jellycat.com" target="_top">
                orders@jellycat.com
              </a>{" "}
              for further assistance.
            </P>
          )
        });
      });
  }

  handleRedirectToOrderDetailsView() {
    const { backorderItem } = this.state;
    this.props.history.push({
      pathname: `/profile/orders/${backorderItem.order_id}`,
      state: {
        orderId: backorderItem.order_id,
        orderDate: backorderItem.order_date
        // backorderItems: backorderItems
      }
    });
  }

  render() {
    const {
      modalIsVisible,
      modalIncreaseBackordersIsVisible,
      confirmLoading,
      error
    } = this.state;
    const {
      src,
      productName,
      tradePrice,
      suggestedRetailPrice,
      code,
      height,
      packQty,
      barcode,
      status
    } = this.props;

    if (error) {
      return <ErrorMessageGeneral />;
    }

    return (
      <div style={{ height: "100%", position: "relative" }}>
        <Container onClick={() => this.showModal()}>
          {status === "DISCON_RMG" && <ProductBadge status={status} />}
          <ImageFixedProportions
            src={src ? src : ""}
            placeholderPng={ImageProductPlaceholderPng}
          />
          <div style={{ textAlign: "left" }}>
            <ProductNameDiv>
              <P colorTextGray fontWeightHeavy wide>
                {productName} ({code})
              </P>
            </ProductNameDiv>
            <DescriptionDiv>
              <P className="mb-2 mb-sm-0" colorTextGray small>
                Min Retail Price:
              </P>
              <P className="mb-2 mb-sm-0" colorTextGray fontWeightHeavy small>
                ${suggestedRetailPrice.toFixed(2)}
              </P>
            </DescriptionDiv>
            <DescriptionDiv>
              <P className="mb-2 mb-sm-0" colorTextGray small>
                Trade Price:
              </P>
              <P className="mb-2 mb-sm-0" colorTextGray fontWeightHeavy small>
                ${tradePrice.toFixed(2)}
              </P>
            </DescriptionDiv>
            <DescriptionDiv>
              <P className="mb-2 mb-sm-0" colorTextGray small>
                Pack Quantity:
              </P>
              <P className="mb-2 mb-sm-0" colorTextGray fontWeightHeavy small>
                {packQty}
              </P>
            </DescriptionDiv>
            <DescriptionDiv>
              <P className="mb-2 mb-sm-0" colorTextGray small>
                Height:
              </P>
              <P className="mb-2 mb-sm-0" colorTextGray fontWeightHeavy small>
                {height}cm
              </P>
            </DescriptionDiv>
            <DescriptionDiv>
              <P className="mb-2 mb-sm-0" colorTextGray small>
                Barcode:
              </P>
              <P className="mb-2 mb-sm-0" colorTextGray fontWeightHeavy small>
                {parseInt(barcode)}
              </P>
            </DescriptionDiv>
          </div>
        </Container>

        <Modal
          title={`${productName} (${code})`}
          visible={modalIsVisible}
          onCancel={() => this.handleCloseModal()}
          footer={[
            <Button
              key="submit"
              type="primary"
              loading={confirmLoading}
              onClick={e => this.handleAddToCart(e)}
            >
              Add to Cart
            </Button>
          ]}
        >
          <div>
            <div>
              <P>{`Height: ${height}cm`}</P>
              <P>{`Barcode: ${parseInt(barcode)}`}</P>
            </div>

            <form method="post">
              <InputSelect
                label={"Quantity: "}
                data={valuesForProductQtyInputSelect(packQty)}
                // value={this.props.packQty}
                initialValue={this.props.packQty}
                onChange={e => this.handleInputSelectChange(e)}
                name={"quantity"}
                addedToCart={this.state.addedToCart}
              />
            </form>
          </div>
        </Modal>
        <Modal
          title={`${productName} (${code})`}
          visible={modalIncreaseBackordersIsVisible}
          onCancel={() => this.handleCloseBackordersModal()}
          footer={null}
        >
          <div>
            <P>You currently have this item on backorder.</P>
            <P>
              To order more of this item, you can increase its backorder
              quantity{" "}
              <span
                style={{ cursor: "pointer", color: "blue" }}
                onClick={() => this.handleRedirectToOrderDetailsView()}
              >
                here.
              </span>
            </P>
          </div>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    showCartPopover: state.cart.showCartPopover,
    updateCartBadge: state.cart.updateCartBadge
  };
};

export default connect(mapStateToProps)(withRouter(Product));
