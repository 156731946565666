export function valuesForProductQtyInputSelect(packQty, value = 120) {
  let values = [];
  let numOfIterations;
  if (value > 120) {
    numOfIterations = value / packQty;
  } else {
    numOfIterations = 120 / packQty;
  }

  for (var i = 1; i <= numOfIterations; i++) {
    values.push([packQty * i.toString(), packQty * i]);
  }
  return values;
}
