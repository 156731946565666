import React from 'react'
import { css } from 'glamor'
import glamorous from 'glamorous'
import PropTypes from 'prop-types'

const AnimatedDiv = glamorous.div(
  props => ({
    position: 'relative',
    display: 'inline-block',
    border: `5px solid ${props.theme.colorGrayLightest}`,
    borderRadius: '50%',
    borderTop: `5px solid ${props.theme.colorPrimary}`,
    width: '25px',
    height: '25px',
    animation: `${css.keyframes({
      '0%': { transform: 'rotate(0deg)' },
      '100%': { transform: 'rotate(360deg)' },
    })} 2s infinite linear `,
  }),
  props =>
    props.floating && {
      position: 'fixed',
      top: '50%',
      left: '50%',
      zIndex: props.theme.zIndexLoading,
    },
  props =>
    props.big && {
      border: `7px solid ${props.theme.colorGrayLightest}`,
      borderTop: `7px solid ${props.theme.colorPrimary}`,
      width: '50px',
      height: '50px',
    },
  props =>
    props.centered && {
      display: 'block',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  props =>
    props.marginVertical && {
      marginTop: '2em',
      marginBottom: '2em',
    }
)

class IconLoadingSpinner extends React.Component {
  static propTypes = {
    big: PropTypes.bool,
    centered: PropTypes.bool,
    floating: PropTypes.bool,
  }

  static defaultProps = {
    big: false,
    centered: false,
    floating: false,
  }

  render() {
    const { big, centered, floating, css, marginVertical } = this.props
    return (
      <AnimatedDiv
        centered={centered}
        floating={floating}
        big={big}
        css={css}
        marginVertical={marginVertical}
      />
    )
  }
}

export default IconLoadingSpinner
