import glamorous from 'glamorous'

const InputFormError = glamorous.div(
  props => ({
    color: props.theme.colorDanger,
    fontFamily: props.theme.fontPrimary,
    fontSize: props.theme.fontSizePSmall,
  }),
  props => props.noPointer && { cursor: 'text' }
)

InputFormError.displayName = 'web.inputs.InputFormError'

export default InputFormError
